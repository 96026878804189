import React, { useState, useEffect } from "react";
import Toast from "../Utils/Toast";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import { ApiEndPoints } from "../ApiEndPoints";
import { Menu, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./ManageTimeSheet.scss";
import { Button_TEXTS, TimeSheetDuration } from "../../constants";
import { Tooltip } from "@mui/material";

const getPreviousSunday = () => {
  const date = new Date();
  var dayOfWeek = date.getDay();
  var diff = date.getDate() - dayOfWeek;
  var startOfWeek = new Date(date);
  startOfWeek.setDate(diff);

  startOfWeek.setHours(0);
  startOfWeek.setMinutes(0);
  startOfWeek.setSeconds(0);
  startOfWeek.setMilliseconds(0);

  return startOfWeek;
};

function formatDate(date) {
  var day = String(date.getDate()).padStart(2, "0");
  var month = String(date.getMonth() + 1).padStart(2, "0");
  var year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateOnlyDateAndMonth(date) {
  var day = String(date.getDate()).padStart(2, "0");
  var month = String(date.getMonth() + 1).padStart(2, "0");

  return `${day}/${month}`;
}

const daysArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let days = [];

function generateDays(n) {
  days = [];
  for (let i = 0; i < n; i++) {
    days.push(daysArray[i % daysArray.length]);
  }
  return days;
}

const ManageTimeSheet = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const [startDate, setStartDate] = useState(getPreviousSunday());
  const [t_data, setT_data] = useState();
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [projectIds, setProjectIds] = useState();
  const [taskIds, setTaskIds] = useState();
  const [userData, setUserData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [user_Id, setUser_Id] = useState();
  const [searchError, setSearchError] = useState("");
  const [isSearchEnable, setIsSearchEnable] = useState(false);


  function getAllProject() {
    axios
      .get(`${ApiEndPoints.getProjectByUserId}?employee_id=${user_Id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const projects = res.data.data.projects;
        setProjectIds(projects);
      })
      .catch((error) => {});
  }

  function getAllTask() {
    const url = `${ApiEndPoints.getTasks}user_id=${user_Id}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const task = res.data.data.tasks;
        setTaskIds(task);
      })
      .catch((error) => {});
  }

  

  const getTimeSheet = (id) => {
    setShowToast(false);
    setT_data();
    if (id) {
      const sdate = startDate.getTime();
      const edate = endOfWeek().getTime();
      const num_days = TimeSheetDuration;
      const url = `${ApiEndPoints.getTimeSheet}?user_id=${id}&start_date=${sdate}&end_date=${edate}&timesheet_status_id=2`;
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(async (response) => {
          let data = await response.json();
          if (data === null) {
            setShowToast(true);
            setType("success");
            setMessage("No data Found");
          }
          data = data.map((k) => {
            const timesheet_details = [];
            for (let h = 0; h < num_days; h++) {
              const date = new Date(startDate);
              date.setDate(date.getDate() + h);
              const cell = k.timesheet_details.find((m) =>
                isDateWithinDay(m.date_time, date)
              );
              if (cell) {
                timesheet_details.push(cell);
              } else {
                timesheet_details.push({
                  date_time: date.getTime(),
                  work_hours: "",
                });
              }
            }
            return { ...k, timesheet_details };
          });

          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            setT_data(data);
          }
        })
        .catch((error) => {});
    } else {
      setSearchError("Please select an User");
    }
  };

  const endOfWeek = () => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + (TimeSheetDuration - 1));
    return date;
  };



  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      setT_data();
      getAllProject();
      getAllTask();
      generateDays(TimeSheetDuration);
      getTimeSheet(user_Id);
    } else {
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, user_Id]);

  const getEmployees = () => {
    setUserData(null);
    const url = `${ApiEndPoints.searchUser}?search_term=${searchTerm}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const users = res.data.data.users;
        setUserData(users);
        setAnchorEl(document.getElementById("search-input"));
      })
      .catch((err) => console.log(err));
  };

  const updateDate = (daysCount) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + daysCount);
    setT_data();
    setStartDate(date, () => {
      getTimeSheet(user_Id);
    });
  };

  function getProjectNameById(projectID) {
    const project = projectIds.find((p) => p.project_id === projectID);
    return project ? project.project_name : "Not selected";
  }

  const ProjectDrop = ({ val, newItem, rowIndex }) => (
    <Tooltip title={getProjectNameById(val)} arrow>
      <select
        className="dropdown"
        name="project_id"
        defaultValue={val}
        disabled={!newItem}
      >
        <option value="-1" disabled>
          Select Project
        </option>

        {projectIds &&
          projectIds.map((project) => (
            <option key={project.project_id} value={project.project_id}>
              {project.project_name}
            </option>
          ))}
      </select>
    </Tooltip>
  );

  function getTaskNameById(taskID) {
    const task = taskIds.find((t) => t.task_id === taskID);
    console.log(task, taskID);
    return task ? task.task_description : "Not selected";
  }

  const TaskDropDown = ({ val, newItem, rowIndex }) => (
    <Tooltip title={getTaskNameById(val)} arrow>
      <select
        className="dropdown"
        name="task_id"
        disabled={!newItem}
        defaultValue={val}
      >
        <option value="-1" disabled>
          Select Task
        </option>
        {taskIds &&
          taskIds.map((project) => (
            <option key={project.task_id} value={project.task_id}>
              {project.task_description}
            </option>
          ))}
      </select>
    </Tooltip>
  );

  function isDateWithinDay(dateToCheck, targetDate) {
    // Set time part of targetDate to 00:00
    var startOfDay = new Date(targetDate);
    startOfDay.setHours(0, 0, 0, 0);

    // Set time part of targetDate to 23:59:59:999
    var endOfDay = new Date(targetDate);
    endOfDay.setHours(23, 59, 59, 999);

    // Check if dateToCheck falls within the day
    return dateToCheck >= startOfDay && dateToCheck <= endOfDay;
  }

  const dateHeader = (pos) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + pos);
    return formatDateOnlyDateAndMonth(date);
  };

  const saveUpdate = (data) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const url = `${ApiEndPoints.addTimeSheet}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const data = await response.json();
        console.log(data, "dataaa");
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          setTimeout(() => {
            getTimeSheet(user_Id);
          }, 300);
        }
      })
      .catch((error) => {});
  };

  const handleApprove = (id) => {
    setShowToast(false);
    const updatedTimesheetDetails = id.timesheet_details
      .map((timesheetDetail) => {
        const updatedDetail = { ...timesheetDetail };
        updatedDetail.timesheet_status_id = 3;
        return updatedDetail;
      })
      .filter((entry) => entry.work_hours !== "");
    const updatedTimesheet = {
      ...id,
      timesheet_details: updatedTimesheetDetails,
    };
    saveUpdate([updatedTimesheet]);
  };

  const handleReject = (id) => {
    setShowToast(false);
    const updatedTimesheetDetails = id.timesheet_details
      .map((timesheetDetail) => {
        const updatedDetail = { ...timesheetDetail };
        updatedDetail.timesheet_status_id = 4;
        return updatedDetail;
      })
      .filter((entry) => entry.work_hours !== "");
    const updatedTimesheet = {
      ...id,
      timesheet_details: updatedTimesheetDetails,
    };
    saveUpdate([updatedTimesheet]);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    let timer;
    if (searchTerm && isSearchEnable) {
      timer = setTimeout(() => {
        setUserData(null);

        getEmployees();
      }, 2000);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm.length < 2) {
      setSearchError("Search term must be at least 3 characters to search ");
    } else {
      setIsSearchEnable(true);
      setSearchError("");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setUser_Id(option.user_id);
    getTimeSheet(option.user_id);
    setSelectedOption(`${option.first_name} ${option.last_name}`);
    setAnchorEl(null); // Close the menu after selecting an option
  };

  return (
    <div className="timesheet-container">
      <p>Manage Employee Timesheet</p>
      <div className="container-header-date">
        <button onClick={() => updateDate(-(TimeSheetDuration))}>
          <ChevronLeftIcon
            style={{ background: "#187498", cursor: "pointer" }}
          />
        </button>
        {formatDate(startDate)} to {formatDate(endOfWeek())}
        <button onClick={() => updateDate(TimeSheetDuration)}>
          <ChevronRightIcon
            style={{
              background: "#187498",

              overflow: "hidden",
              cursor: "pointer",
            }}
          />
        </button>
      </div>
      <div className="search-section">
        <div>
          <div className="search-container">
            <input
              id="search-input"
              label="Search"
              placeholder="Search Employee"
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
            <SearchIcon style={{ cursor: "pointer" }} />
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {userData &&
              userData.map((option) => (
                <MenuItem
                  key={option.user_id}
                  onClick={() => handleMenuItemClick(option)}
                >
                  {option.first_name} {option.last_name}
                </MenuItem>
              ))}
          </Menu>
        </div>

        <div className="selected-employee">
          {selectedOption && (
            <h3>Employee Name: {selectedOption ? selectedOption : "None"}</h3>
          )}
        </div>
        {searchError && <div className="search-error">{searchError}</div>}
      </div>

      <table className="timesheet-table">
        {t_data && (
          <thead className="table-head">
            <tr>
              <th className="table-headers-dropdown">Project</th>
              <th className="table-headers-dropdown">Task</th>
              {days.map((formattedDate, index) => (
                <th
                  key={`${formattedDate}-${index}`}
                  style={{ paddingRight: 8 }}
                  className="table-headers"
                >
                  {dateHeader(index)}
                </th>
              ))}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {days.map((days, index) => (
                <th key={`${days}-${index}`}>{days}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="table-body">
          {t_data?.map((k, pId) => (
            <tr key={`${k.timesheet_id}-${pId}`}>
              <td>
                <ProjectDrop
                  val={k.project_id}
                  newItem={k.newRow}
                  rowIndex={pId}
                />
              </td>
              <td>
                <TaskDropDown
                  val={k.task_id}
                  newItem={k.newRow}
                  rowIndex={pId}
                />
              </td>
              {k.timesheet_details.map((s, cId) => (
                <td key={`${s.timesheet_details_id}+${cId}`}>
                  <input
                    name={`${s.timesheet_details_id}+${cId}`}
                    defaultValue={s.work_hours}
                    style={{ textAlign: "center" }}
                  />
                </td>
              ))}
              <td>
                <button
                  className="approve-btn"
                  onClick={() => handleApprove(k)}
                >
                  Approve
                </button>
              </td>
              <td>
                <button className="reject-btn" onClick={() => handleReject(k)}>
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default ManageTimeSheet;
