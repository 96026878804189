import React, { useState, useEffect } from "react";
import axios from "axios";
import {ApiEndPoints} from "../ApiEndPoints";
import { Grid, Button, Menu, MenuItem, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./ManageAttendence.scss";
import Toast from "../Utils/Toast";
import DataTable from "../DataTable/DataTable";

const ManageAttendence = () => {
  const accessToken = sessionStorage.getItem("accessToken");

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const header = [
    { key: "start_date_time", value: "In Time" },
    { key: "end_date_time", value: "Out Time" },
    { key: "total_hours", value: "Total Time (in Hours)" },
  ];

  const [page, setPage] = useState({
    totalRows: 10,
    currentPage: 1,
    rowsPerPage: 5,
  });
  const [isMounted, setIsMounted] = useState(false);
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [error, setError] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [attendenceData, setAttendenceData] = useState(null);
  const [userData, setUserData] = useState();
  const [user_Id, setUser_Id] = useState(null);
  const [searchError, setSearchError] = useState("");
  const [isSearchEnable, setIsSearchEnable] = useState(false);

  useEffect(() => {
    if (isMounted) {
      if (selectedOption === "weekly") {
        getWeeklyAttendence();
      } else if (selectedOption === "monthly") {
        getMonthlyAttendence();
      } else if (selectedOption === "range" && isButtonEnabled) {
        getAttendence(startDate, endDate);
      }
    } else {
      setIsMounted(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page.rowsPerPage,
    page.currentPage,
    selectedOption,
    startDate,
    endDate,
    sortOrder,
  ]);

  const handleChangePage = (num) => {
    setPage({ ...page, currentPage: num });
  };
  const handleChangeRowsPerPage = (row) => {
    setPage({ ...page, rowsPerPage: row, currentPage: 1 });
  };
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const getEmployees = (name) => {
    const url = `${ApiEndPoints.searchUser}?search_term=${searchTerm}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const users = res.data.data.users;
        setUserData(users);
        setAnchorEl(document.getElementById("search-input"));
      })
      .catch((err) => console.log(err));
  };

  function getPreviousWeekDate() {
    const currentDate = new Date();
    const previousWeekDate = new Date(
      currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
    );
    return previousWeekDate;
  }

  function getPreviousMonthDate() {
    const currentDate = new Date();
    const previousWeekDate = new Date(
      currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
    );
    return previousWeekDate;
  }

  function getWeeklyAttendence() {
    const endDate = new Date().getTime();
    const startDate = getPreviousWeekDate().getTime();
    getAttendence(startDate, endDate);
  }

  function getMonthlyAttendence() {
    const endDate = new Date().getTime();
    const startDate = getPreviousMonthDate().getTime();
    getAttendence(startDate, endDate);
  }

  function getAttendence(start_date, end_date) {
    setAttendenceData(null);
    const url = `${ApiEndPoints.getUserAttendence}?page=${page.currentPage}&page_size=${page.rowsPerPage}&user_id=${user_Id}&start_date=${start_date}&end_date=${end_date}&sort_by=${sortOrder}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const datas = data.data.attendances;
          setPage({ ...page, totalRows: data.data.total_attendance });

          function timestampConversion(timestamp) {
            const date = new Date(timestamp);
            const options = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              timeZone: "Asia/Kolkata",
            };
            const dateTimeString = date.toLocaleString("en-IN", options);
            return dateTimeString;
          }
          const convertedData = datas.map((attendance) => ({
            ...attendance,
            start_date_time: timestampConversion(attendance.start_date_time),
            end_date_time: timestampConversion(attendance.end_date_time),
          }));
          setAttendenceData(convertedData);
        }
      })
      .catch((error) => {});
  }
  const [errorMessage, setErrorMessage] = useState("");

  const handleOptionChange = (event) => {
    if (user_Id === null) {
      setErrorMessage("Please select an employee  first.");
      event.preventDefault();
    } else {
      setErrorMessage("");
      setSelectedOption(event.target.value);
    }
  };

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    if (selectedStartDate > endDateValue && endDateValue !== "") {
      setError("Start date must be before end date");
    } else {
      setStartDateValue(selectedStartDate);
      setError("");
      updateButtonState(selectedStartDate, endDateValue, error);
    }
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    if (selectedEndDate < startDateValue && startDateValue !== "") {
      setError("End date must be after start date");
    } else {
      setEndDateValue(selectedEndDate);
      setError("");
      updateButtonState(startDateValue, selectedEndDate, error);
    }
  };

  const updateButtonState = (start, end, error) => {
    setIsButtonEnabled(start !== "" && end !== "" && error === "");
  };

  function dateToEpochTime(dateString) {
    const date = new Date(dateString);
    return date.getTime();
  }

  const handleGetData = () => {
    setStartDate(dateToEpochTime(startDateValue));
    setEndDate(dateToEpochTime(endDateValue));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChoice, setSelectedChoice] = useState(null);

  useEffect(() => {
    let timer;
    if (searchTerm && isSearchEnable) {
      timer = setTimeout(() => {
        setUserData(null);
        setPage({ ...page, currentPage: 1 });
        getEmployees();
      }, 2000);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm.length < 2) {
      setSearchError("Search term must be at least 3 characters to search ");
    } else {
      setIsSearchEnable(true);
      setSearchError("");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setUser_Id(option.user_id);
    setErrorMessage("");
    setSelectedChoice(`${option.first_name} ${option.last_name}`);
    setAnchorEl(null); // Close the menu after selecting an option
  };

  return (
    <div className="attendence-manage-section">
      <h1 className="headers">Get employee attendence</h1>
      <div className="search-section">
        <div>
          <div className="search-container">
            <input
              id="search-input"
              label="Search"
              placeholder="Search Employee"
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
            <SearchIcon style={{ cursor: "pointer" }} />
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {userData &&
              userData.map((option) => (
                <MenuItem
                  key={option.user_id}
                  onClick={() => handleMenuItemClick(option)}
                >
                  {option.first_name} {option.last_name}
                </MenuItem>
              ))}
          </Menu>
        </div>

        <div>
          {selectedChoice && (
            <h3>Employee Name: {selectedChoice ? selectedChoice : "None"}</h3>
          )}
        </div>
        {searchError && <div className="search-error">{searchError}</div>}
      </div>

      <div className="fourth-section">
        <div className="main-div">
          <h2 className="tittle-header">My Attendence</h2>
          <div className="radio-container">
            <label className="radio-button-section">
              <input
                id="weekly"
                type="radio"
                value="weekly"
                checked={selectedOption === "weekly"}
                onChange={handleOptionChange}
              />
              Weekly
            </label>
            <label className="radio-button-section">
              <input
                id="monthly"
                type="radio"
                value="monthly"
                checked={selectedOption === "monthly"}
                onChange={handleOptionChange}
              />
              Monthly
            </label>
            <label className="radio-button-section">
              <input
                id="range"
                type="radio"
                value="range"
                checked={selectedOption === "range"}
                onChange={handleOptionChange}
              />
              Range
            </label>
            {selectedOption === "range" && (
              <div>
                <Grid container spacing={4} className="date-range-box">
                  <Grid item xs={4}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDateValue}
                      onChange={handleStartDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="End Date"
                      type="date"
                      value={endDateValue}
                      onChange={handleEndDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGetData}
                      disabled={!isButtonEnabled}
                    >
                      Get Attendence
                    </Button>
                  </Grid>
                </Grid>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            )}
          </div>
        </div>
        {errorMessage && <h3 className="alert-message"> {errorMessage}</h3>}

        <DataTable
          columns={header}
          rows={attendenceData}
          sortOrder={sortOrder}
          toggleSortOrder={toggleSortOrder}
          pageConfig={page}
          pageChanged={handleChangePage}
          rowsChanged={handleChangeRowsPerPage}
        />
      </div>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default ManageAttendence;
