import React, { useState } from "react";
import { TextField, Grid, Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Toast from "../Utils/Toast";
import "./EditClient.scss";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { ApiEndPoints } from "../ApiEndPoints";
import { Button_TEXTS, TEXT_MSGS } from "../../constants";

const EditClient = () => {
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const location = useLocation();
  const { state } = location;
  const datas = state && state.singleProp;
  const [formData, setFormData] = useState(datas);
  const originalData=datas;
  const [updated, setUpdated] = useState(false);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    client_name: "",
    contact_person_name: "",
    email: "",
    telephone_1: "",
  });

  const handleInputChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
  };

  const validateClientName = () => {
    const firstNameRegex = /^[a-zA-Z\s]*$/;

    if (!formData.client_name || !firstNameRegex.test(formData.client_name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        client_name: "Client name must contain only letters",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      client_name: "",
    }));
    return true;
  };

  const validateContactPersonName = () => {
    const firstNameRegex = /^[a-zA-Z\s]*$/;
    if (
      !formData.contact_person_name ||
      !firstNameRegex.test(formData.contact_person_name)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact_person_name: "Name must contain only letters",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      contact_person_name: "",
    }));
    return true;
  };

  //Validation for email
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const trimmedEmail = formData.email.trim();

    if (!trimmedEmail || !emailRegex.test(trimmedEmail)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      return false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));

    return true;
  };

  const validateMobileNumber = () => {
    const mobileRegex = /^\+?[0-9]{10,13}$/;
    if (!formData.telephone_1 || !mobileRegex.test(formData.telephone_1)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        telephone_1:
          "Mobile number must be 10 to 13 digits, optionally starting with a '+' for the country code",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      telephone_1: "",
    }));
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const editedData = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== originalData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (
      validateClientName() &&
      validateContactPersonName() &&
      validateEmail() &&
      validateMobileNumber()
    ) {
      const accessToken = sessionStorage.getItem("accessToken");
      const ClientId = formData.client_id;
      const url = `${ApiEndPoints.editClient}/${ClientId}`;
      fetch(url, {
        method: "PUT",

        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(editedData),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            setTimeout(() => {
              navigate("/dashboard/clients");
            }, 1000);
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <div div className="editClient-section">
      <Link to="/dashboard/clients" className="navigation-section">
        <div className="client-list-backarrow">
          <ChevronLeftIcon /> {TEXT_MSGS.back}
        </div>
      </Link>
      <div>
        <h2 className="edit-client-header">Edit Client</h2>
        <form onSubmit={handleSubmit} className="form-wrapper">
          <Grid
            container
            spacing={2}
            className="editClient-section"
            columnSpacing={{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                label="Client Name"
                fullWidth
                required
                helperText={
                  <span className="helper-text">{errors.client_name}</span>
                }
                name="client_name"
                value={formData.client_name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact person Name"
                fullWidth
                required
                name="contact_person_name"
                helperText={
                  <span className="helper-text">
                    {errors.contact_person_name}
                  </span>
                }
                value={formData.contact_person_name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact person email"
                fullWidth
                required
                name="email"
                helperText={<span className="helper-text">{errors.email}</span>}
                value={formData.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact person mobile number"
                fullWidth
                helperText={
                  <span className="helper-text">{errors.telephone_1}</span>
                }
                required
                name="telephone_1"
                value={formData.telephone_1}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                fullWidth
                required
                disabled
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                fullWidth
                required
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="City"
                fullWidth
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                fullWidth
                required
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            disabled={!updated}
            className={`button-${updated ? "enabled" : "disabled"}`}
            onSubmit={handleSubmit}
          >
            {Button_TEXTS.updateClient}
          </Button>
        </form>

        {showToast && <Toast type={type} message={message} />}
      </div>
    </div>
  );
};

export default EditClient;
