import React, { useState, useEffect } from "react";
import Toast from "../Utils/Toast";
import { Tooltip } from "@mui/material";
import "./TimeSheet.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import { ApiEndPoints } from "../ApiEndPoints";
import { Button_TEXTS, TimeSheetDuration } from "../../constants";

const getPreviousSunday = () => {
  const date = new Date();
  var dayOfWeek = date.getDay();
  var diff = date.getDate() - dayOfWeek;
  var startOfWeek = new Date(date);
  startOfWeek.setDate(diff);
  startOfWeek.setHours(0);
  startOfWeek.setMinutes(0);
  startOfWeek.setSeconds(0);
  startOfWeek.setMilliseconds(0);
  return startOfWeek;
};

function formatDate(date) {
  // Format dates to dd/mm/yyyy
  var day = String(date.getDate()).padStart(2, "0");
  var month = String(date.getMonth() + 1).padStart(2, "0");
  var year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateandMonth(date) {
  // Format dates to dd/mm
  var day = String(date.getDate()).padStart(2, "0");
  var month = String(date.getMonth() + 1).padStart(2, "0");
  return `${day}/${month}`;
}

const daysArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let daysHeader = [];

function generateDays(n) {
  daysHeader = [];
  for (let i = 0; i < n; i++) {
    daysHeader.push(daysArray[i % daysArray.length]);
  }
  return daysHeader;
}

const TimeSheet = () => {
  const userId = sessionStorage.getItem("userId");
  const accessToken = sessionStorage.getItem("accessToken");
  const [startDate, setStartDate] = useState(getPreviousSunday());
  const [t_data, setT_data] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [projectIds, setProjectIds] = useState();
  const [taskIds, setTaskIds] = useState();

  useEffect(() => {
    getAllProject();
    getAllTask();
    generateDays(TimeSheetDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTimeSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const addRow = () => {
    const userRow = {
      project_id: -1,
      task_id: -1,
      user_id: parseInt(userId),
      newRow: true,
      timesheet_details: [...Array(TimeSheetDuration)].map((p, l) => {
        const date = new Date(startDate);
        date.setDate(date.getDate() + l);
        return {
          date_time: date.getTime(),
          work_hours: "",
        };
      }),
    };
    t_data.push({ ...userRow });
    setT_data([...t_data]);
  };

  function getAllProject() {
    axios
      .get(`${ApiEndPoints.getProjectByUserId}?employee_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const projects = res.data.data.projects;
        console.log(projects);
        setProjectIds(projects);
      })
      .catch((error) => {});
  }

  function getAllTask() {
    const url = `${ApiEndPoints.getTasks}user_id=${userId}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const task = res.data.data.tasks;
        setTaskIds(task);
      })
      .catch((error) => {});
  }

  const getTimeSheet = () => {
    setShowToast(false);
    const sdate = startDate.getTime();
    const edate = endOfWeek().getTime();
    const num_days = TimeSheetDuration;
    const url = `${ApiEndPoints.getTimeSheet}?user_id=${userId}&start_date=${sdate}&end_date=${edate}&timesheet_status_id=0`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        let data = await response.json();

        data = data.map((k) => {
          const timesheet_details = [];
          for (let h = 0; h < num_days; h++) {
            const date = new Date(startDate);
            date.setDate(date.getDate() + h);
            const cell = k.timesheet_details.find((m) =>
              isDateWithinDay(m.date_time, date)
            );
            if (cell) {
              timesheet_details.push(cell);
            } else {
              timesheet_details.push({
                date_time: date.getTime(),
                work_hours: "",
              });
            }
          }
          return { ...k, timesheet_details };
        });
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          setT_data(data);
        }
      })
      .catch((error) => {});
  };

  const endOfWeek = () => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + (TimeSheetDuration - 1));
    return date;
  };

  const updateDate = (daysCount) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + daysCount);
    setStartDate(date);
    setT_data([]);
  };

  const handleInputChange = (i, e, isProject) => {
    const value = Number(e.target.value);
    t_data[i][isProject ? "project_id" : "task_id"] = value;
    setT_data([...t_data]);
  };

  function getProjectNameById(projectID) {
    const project = projectIds?.find((p) => p.project_id === projectID);
    return project ? project.project_name : "Not selected";
  }

  const ProjectDrop = ({ val, newItem, rowIndex }) => (
    <Tooltip title={getProjectNameById(val)} arrow>
      <select
        className="dropdown"
        name="project_id"
        defaultValue={val}
        disabled={!newItem}
        onChange={(event) => handleInputChange(rowIndex, event, true)}
      >
        <option value="-1" disabled>
          Select Project
        </option>

        {projectIds &&
          projectIds.map((project) => (
            <option key={project.project_id} value={project.project_id}>
              {project.project_name}
            </option>
          ))}
      </select>
    </Tooltip>
  );

  function getTaskNameById(taskID) {
    const task = taskIds?.find((t) => t.task_id === taskID);
    return task ? task.task_description : "Not selected";
  }

  const TaskDropDown = ({ val, newItem, rowIndex }) => (
    <Tooltip title={getTaskNameById(val)} arrow>
      <select
        className="dropdown"
        name="task_id"
        disabled={!newItem}
        defaultValue={val}
        onChange={(event) => handleInputChange(rowIndex, event, false)}
      >
        <option value="-1" disabled>
          Select Task
        </option>
        {taskIds &&
          taskIds.map((project) => (
            <option key={project.task_id} value={project.task_id}>
              {project.task_description}
            </option>
          ))}
      </select>
    </Tooltip>
  );

  function isDateWithinDay(dateToCheck, targetDate) {
    var startOfDay = new Date(targetDate);
    startOfDay.setHours(0, 0, 0, 0);
    var endOfDay = new Date(targetDate);
    endOfDay.setHours(23, 59, 59, 999);
    return dateToCheck >= startOfDay && dateToCheck <= endOfDay;
  }

  const dateHeader = (pos) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + pos);
    return formatDateandMonth(date);
  };

  const onUpdateValue = (sd, e) => {
    const value = Number(e.target.value);
    if (sd.work_hours !== "") {
      sd.edited = true;
      sd.work_hours = value;
    }
    if (sd.work_hours === "" && value > 0 && value < 13) {
      sd.new = true;
      sd.work_hours = value;
    }
  };

  const getRequest = (isSave) => {
    const saveDetails = [];
    for (let j = 0; j < t_data.length; j++) {
      if (t_data.length <= 1) {
        delete t_data[0].newRow;
      }
      if (t_data[j].timesheet_details.some((k) => k.edited || k.new)) {
        saveDetails.push({ ...t_data[j], timesheet_details: [] });
        for (let l = 0; l < t_data[j].timesheet_details.length; l++) {
          const timeSheetItem = t_data[j].timesheet_details[l];
          if (timeSheetItem.edited) {
            timeSheetItem.timesheet_status_id = isSave ? 1 : 2;
            saveDetails[saveDetails.length - 1].timesheet_details.push(
              timeSheetItem
            );
          }
          if (timeSheetItem.new) {
            timeSheetItem.timesheet_status_id = 1;
            delete timeSheetItem.new;
            saveDetails[saveDetails.length - 1].timesheet_details.push(
              timeSheetItem
            );
          }
        }
      }
    }
    return saveDetails;
  };

  const saveMe = () => {
    setShowToast(false);
    const request = getRequest(true);
    if (request.length > 0) {
      fetch(ApiEndPoints.addTimeSheet, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(request),
      })
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            getTimeSheet();
          }
        })
        .catch((error) => {});
    } else {
      setShowToast(true);
      setType("error");
      setMessage("Please enter valid Input (Numbers Only (1 - 12))");
    }
  };

  const submitData = () => {
    setShowToast(false);
    const updatedFilteredData = t_data
      .map((item) => ({
        ...item,
        timesheet_details: item.timesheet_details
          .map((entry) => ({
            ...entry,
            timesheet_status_id: entry.timesheet_status_id === 1 ? 2 : 0,
          }))
          .filter((entry) => entry.timesheet_status_id === 2),
      }))
      .filter((item) => item.timesheet_details.length > 0);

    if (updatedFilteredData.length > 0) {
      setShowToast(false);
      const url = `${ApiEndPoints.addTimeSheet}`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(updatedFilteredData),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            getTimeSheet();
          }
        })
        .catch((error) => {});
    } else {
      setShowToast(true);
      setType("error");
      setMessage("No data Available to Submit");
    }
  };

  const specialDates = [
    { date: "21/04", label: " Day" },
    { date: "01/04", label: " Day" },
    { date: "21/05", label: " Day" },
    { date: "21/05", label: " Day" },
  ];

  const specialDateIndexes = [];

  return (
    <div className="employee-timesheet">
      <div className="container-header-date">
        <button onClick={() => updateDate(-TimeSheetDuration)}>
          <ChevronLeftIcon
            style={{ background: "#187498", cursor: "pointer" }}
          />
        </button>
        {formatDate(startDate)} to {formatDate(endOfWeek())}
        <button onClick={() => updateDate(TimeSheetDuration)}>
          <ChevronRightIcon
            style={{
              background: "#187498",
              border: "none",
              overflow: "hidden",
              cursor: "pointer",
            }}
          />
        </button>
      </div>
      <div className="btn-div">
        <button className="add-btn" onClick={addRow}>
          {Button_TEXTS.addRow}
        </button>
      </div>
      <table className="timesheet-table">
        <thead className="table-head">
          <tr>
            <th className="table-headers-dropdown">Project </th>

            <th className="table-headers-dropdown">Task </th>
            {daysHeader?.map((formattedDate, index) => {
              const currentDate = dateHeader(index);
              const specialDateObject = specialDates.find(
                (specialDate) => specialDate.date === currentDate
              );
              const specialDateIndex = specialDateObject ? index : -1;
              if (specialDateIndex !== -1) {
                specialDateIndexes.push(specialDateIndex);
              }
              const additionalClass = specialDateObject
                ? "special-date-header"
                : "";

              return (
                <th
                  key={`${formattedDate}-${index}`}
                  style={{ paddingRight: 8 }}
                  className={`table-headers ${additionalClass}`}
                >
                  {dateHeader(index)}
                </th>
              );
            })}
          </tr>
          <tr>
            <th></th>
            <th></th>

            {daysHeader &&
              daysHeader.map((days, index) => {
                const additionalClass =
                  days === "Sun" || days === "Sat" ? "weekend-header" : "";
                return (
                  <th
                    key={`${days}-${index}`}
                    className={`table-headers ${additionalClass}`}
                  >
                    {days}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="table-body">
          {t_data?.map((k, pId) => (
            <tr key={`${k.timesheet_id}-${pId}`}>
              <td key={`${k.project_id}-${pId}`}>
                <ProjectDrop
                  val={k.project_id}
                  newItem={k.newRow}
                  rowIndex={pId}
                />
              </td>
              <td key={`${k.project_id}+${pId}`}>
                <TaskDropDown
                  val={k.task_id}
                  newItem={k.newRow}
                  rowIndex={pId}
                />
              </td>
              {k.timesheet_details.map((s, cId) => {
                const isWeekend =
                  cId === 0 || cId === 6 || cId === 7 || cId === 13;
                const isHoliday = specialDateIndexes.includes(cId);
                const additionalClass = `${
                  isWeekend ? "weekends-date-header" : ""
                } ${isHoliday ? "special-index-header" : ""}`.trim();
                return (
                  <td key={`${s.timesheet_details_id}+${cId}`}>
                    <input
                      disabled={k.project_id === -1 || k.task_id === -1}
                      className={`row-${s.timesheet_status_id} ${additionalClass}`}
                      name={`${s.timesheet_details_id}+${cId}`}
                      defaultValue={s.work_hours}
                      onBlur={(e) => onUpdateValue(s, e)}
                      style={{ textAlign: "center" }}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="footer-div">
        <div>
          <div className="colour-div">
            <div className="btn-status-check">
              <div className="status-box save"></div> Save
              <div className="status-box submit"></div> Submit
              <div className="status-box approve"></div> Approved
              <div className="status-box reject"></div> Rejected
              <div className="status-box wekend"></div> Weekend
              <div className="status-box holiday"></div> Holiday
            </div>
          </div>
        </div>
        <div className="footer-button-section">
          <button className="submit-btn save" type="submit" onClick={saveMe}>
            {Button_TEXTS.save}
          </button>
          <button
            className="submit-btn submit"
            type="submit"
            onClick={submitData}
          >
            {Button_TEXTS.submit}
          </button>
        </div>
      </div>

      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default TimeSheet;
