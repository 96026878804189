import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Toast from "../Utils/Toast";
import axios from "axios";
import { ApiEndPoints } from "../ApiEndPoints";
import { Button_TEXTS, TEXT_MSGS } from "../../constants";
import "./EditProject.scss";

const EditProject = () => {
  const location = useLocation();
  const { state } = location;
  const datas = state && state.singleProp;
  const [data, setData] = useState(datas);
  const navigate = useNavigate();
  const [updated, setUpdated] = useState(false);
  const accessToken = sessionStorage.getItem("accessToken");
  const [projectStatus, setProjectStatus] = useState();
  const originalData = datas;
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdated(true);
    let formattedValue = value;
    if (name.includes("_id")) {
      formattedValue = parseInt(value, 10);
    }
    if (name === "start_date" || name === "due_date") {
      const currentDate = new Date();

      const hours = currentDate.getUTCHours();
      const minutes = currentDate.getUTCMinutes();
      const seconds = currentDate.getUTCSeconds();

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}Z`;
      const dateTimeString = `${value}T${formattedTime}`;
      formattedValue = dateTimeString;
    }

    setData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const getProjectStatus = () => {
    axios
      .get(ApiEndPoints.getAllProjectStatus, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const status = res.data;
        setProjectStatus(status);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getProjectStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const editedData = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== originalData[key]) {
        acc[key] = data[key];
      }
      return acc;
    }, {});
    const projectId = data.project_id;
    const url = `${ApiEndPoints.addProject}/${projectId}`;
    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(editedData),
    })
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          setTimeout(() => {
            navigate("/dashboard/projects");
          }, 300);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="edit-project">
      <Link className="project-list-backarrow" to="/dashboard/projects">
        <ChevronLeftIcon /> {TEXT_MSGS.back}
      </Link>

      <form onSubmit={handleSubmit} className="form-wrapper">
        <Grid
          container
          spacing={2}
          columnSpacing={{ xs: 6, sm: 6, md: 6, lg: 6 }}
          className="editProject-section"
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Project Name"
              fullWidth
              disabled
              name="project_name"
              value={data.project_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Client Name"
              fullWidth
              disabled
              name="client_id"
              value={data.client_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Project Code"
              fullWidth
              name="project_code"
              disabled
              value={data.project_code}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Project Manager Name"
              fullWidth
              required
              disabled
              itemType="number"
              name="project_manager_id"
              value={datas.project_manager_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Project Status</InputLabel>
              {projectStatus && (
                <Select
                  labelId="status-label"
                  id="status"
                  name="project_status_id"
                  value={data.project_status_id}
                  onChange={handleInputChange}
                >
                  {projectStatus.map((project) => (
                    <MenuItem
                      key={project.project_status_id}
                      value={project.project_status_id}
                    >
                      {project.project_status_name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Description"
              fullWidth
              name="project_description"
              multiline
              rows={1}
              value={data.project_description}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Due Date"
              name="due_date"
              type="date"
              value={data.due_date.split("T")[0]}
              onChange={handleInputChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          disabled={!updated}
          className={`button-${updated ? "enabled" : "disabled"}`}
        >
          {Button_TEXTS.updateProject}
        </Button>
      </form>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default EditProject;
