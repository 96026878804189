import React, { useState, useEffect } from "react";
import "./Admin.scss";
import { Button, TextField } from "@mui/material";
import { ApiEndPoints } from "../ApiEndPoints";
import Toast from "../Utils/Toast";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import DataTable from "../DataTable/DataTable";
import Popup from "../PopUp/PopUp";
import { DefaultRowsPerPage } from "../../constants";

const Admin = () => {
  const userId = sessionStorage.getItem("userId");
  const [task_name, setTask_name] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const accessToken = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [taskType, setTaskType] = useState();
  const [projectStatusType, setProjectStatusType] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [addProjectStatus, setAddProjectStatus] = useState(false);
  const [addTaskType, setAddTaskType] = useState(false);
  const [addRole, setAddRole] = useState(false);
  const [roles, setRoles] = useState();
  const [newRole, setNewRole] = useState();
  const [department, setDepartment] = useState();
  const [newDepartment, setNewDepartment] = useState();
  const [addDepartment, setAddDepartment] = useState(false);
  const [projectStatus, setProjectStatus] = useState({
    project_status_name: "",
    created_by: parseInt(userId),
    updated_by: parseInt(userId),
  });

  const headers = [{ key: "task_name", value: "Task Name" }];
  const projectHeaders = [
    { key: "project_status_name", value: "Project status name" },
  ];
  const roleHeaders = [{ key: "role_name", value: "Role Name" }];
  const departmentHeaders = [
    { key: "department_name", value: "Department Name" },
  ];

  const [page, setPage] = useState({
    totalRows: 0,
    currentPage: 1,
    rowsPerPage: DefaultRowsPerPage,
  });

  useEffect(() => {
    if (selectedOption === "tasks") {
      setAddProjectStatus(false);
      setAddRole(false);
      setRoles(null);
      setDepartment(null);
      setAddTaskType(false);
      setProjectStatusType(null);
      getAllTasks();
    } else if (selectedOption === "projects") {
      setAddProjectStatus(false);
      setAddRole(false);
      setAddTaskType(false);
      setTaskType(null);
      setRoles(null);
      setDepartment(null);
      getProjectStatus();
    } else if (selectedOption === "role") {
      setAddProjectStatus(false);
      setAddTaskType(false);
      setTaskType(null);
      setDepartment(null);
      setAddDepartment(false);
      setProjectStatusType(null);
      getRoles();
    } else if (selectedOption === "department") {
      setAddProjectStatus(false);
      setAddRole(false);
      setAddTaskType(false);
      setTaskType(null);
      setProjectStatusType(null);
      setRoles(null);
      getDepartment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, page.currentPage, page.rowsPerPage]);

  const handleAddTask = () => {
    setProjectStatusType(null);
    setTaskType(null);
    setAddProjectStatus(false);
    setAddTaskType(true);
    setAddRole(false);
    setAddDepartment(false);
  };

  const handleAddRole = () => {
    setProjectStatusType(null);
    setTaskType(null);
    setRoles(null);
    setAddProjectStatus(false);
    setAddTaskType(false);
    setAddRole(true);
    setAddDepartment(false);
  };

  const handleAddProjectStatus = () => {
    setProjectStatusType(null);
    setTaskType(null);
    setAddTaskType(false);
    setAddProjectStatus(true);
    setAddRole(false);
    setAddDepartment(false);
  };

  const handleAddDepartment = () => {
    setProjectStatusType(null);
    setTaskType(null);
    setRoles(null);
    setDepartment(null);
    setAddProjectStatus(false);
    setAddTaskType(false);
    setAddRole(false);
    setAddDepartment(true);
  };

  const getRoles = () => {
    axios
      .get(ApiEndPoints.getRoles, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setProjectStatusType(null);
        const status = res.data.data;
        setRoles(status);
      })
      .catch((err) => {});
  };

  const getDepartment = () => {
    axios
      .get(ApiEndPoints.getAllDepartments, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setProjectStatusType(null);
        setDepartment(res.data.data);
      })
      .catch((err) => {});
  };

  const getProjectStatus = () => {
    setProjectStatusType(null);
    axios
      .get(ApiEndPoints.getAllProjectStatus, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setProjectStatusType(null);
        const status = res.data;
        setProjectStatusType(status);
      })
      .catch((err) => {});
  };

  const getAllTasks = () => {
    setTaskType(null);
    axios
      .get(
        `${ApiEndPoints.getTaskTypes}?page=${page.currentPage}&page_size=${page.rowsPerPage}&`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        const tasks = res.data.data.taskTypes;
        setPage({ ...page, totalRows: res.data.data.totalCount });
        setTaskType(tasks);
      })
      .catch((err) => {});
  };

  const handleTaskStatusChange = (event) => {
    setTask_name(event.target.value);
  };

  const handleTaskSubmit = (event) => {
    event.preventDefault();
    const apiUrl = ApiEndPoints.addTaskType;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ task_name: task_name }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          setTimeout(() => {
            navigate("/dashboard/home");
          }, 300);
        }
      })
      .catch((error) => {});
  };

  const handlePrjectStatusChange = (event) => {
    setProjectStatus((prevState) => ({
      ...prevState,
      project_status_name: event.target.value,
    }));
  };

  const handleRoleChange = (event) => {
    setNewRole(event.target.value);
  };
  const handleDepartmentChange = (event) => {
    setNewDepartment(event.target.value);
  };

  const handleAddNewRole = (event) => {
    event.preventDefault();
    const apiUrl = ApiEndPoints.addRole;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ role_name: newRole }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          setTimeout(() => {
            navigate("/dashboard/home");
          }, 300);
        }
      })
      .catch((error) => {});
  };

  const handleAddNewDepartment = (event) => {
    event.preventDefault();
    const apiUrl = ApiEndPoints.addDepartmet;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ department_name: newDepartment }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          setTimeout(() => {
            navigate("/dashboard/home");
          }, 300);
        }
      })
      .catch((error) => {});
  };

  const handleProjectStatusSubmit = (event) => {
    event.preventDefault();
    const apiUrl = ApiEndPoints.addProjectStatus;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(projectStatus),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          setTimeout(() => {
            navigate("/dashboard/home");
          }, 300);
        }
      })
      .catch((error) => {});
  };

  const handleChangePage = (num) => {
    setPage({ ...page, currentPage: num });
  };

  const handleChangeRowsPerPage = (row) => {
    setPage({ ...page, rowsPerPage: row, currentPage: 1 });
  };

  const deleteTask = (i) => {
    setShowToast(false);
    const id = taskType[i].task_type_id;
    const url = `${ApiEndPoints.deleteTaskType}/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          taskType.splice(i, 1);
          setTaskType([...taskType]);
        }
      })
      .catch((error) => {});
  };

  const deleteRole = (i) => {
    setShowToast(false);
    const id = roles[i].role_id;
    const url = `${ApiEndPoints.deleteRoles}/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          roles.splice(i, 1);
          setRoles([...roles]);
        }
      })
      .catch((error) => {});
  };

  const deleteProjectStatus = (i) => {
    setShowToast(false);
    const id = projectStatusType[i].project_status_id;
    const url = `${ApiEndPoints.deleteProjectStatus}/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          projectStatusType.splice(i, 1);
          setProjectStatusType([...projectStatusType]);
        }
      })
      .catch((error) => {});
  };

  const deleteDepartment = (i) => {
    setShowToast(false);
    const id = department[i].department_id;
    const url = `${ApiEndPoints.deleteDepartment}/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          department.splice(i, 1);
          setDepartment([...department]);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="admin-section">
      <Popup
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => {
          if (selectedOption === "tasks") {
            deleteTask(deleteIndex);
          } else if (selectedOption === "projects") {
            deleteProjectStatus(deleteIndex);
          } else if (selectedOption === "role") {
            deleteRole(deleteIndex);
          } else if (selectedOption === "department") {
            deleteDepartment(deleteIndex);
          }
          setShowConfirmation(false);
        }}
      />
      {showToast && <Toast type={type} message={message} />}
      <div className="select-container">
        <div className="links">
          <Link
            onClick={() => {
              setSelectedOption("role");
            }}
          >
            Role
          </Link>
          <Link
            onClick={() => {
              setSelectedOption("department");
            }}
          >
            Department
          </Link>
          <Link
            onClick={() => {
              setSelectedOption("projects");
            }}
          >
            Project status
          </Link>
          <Link
            onClick={() => {
              setSelectedOption("tasks");
            }}
          >
            Task type
          </Link>
        </div>

        <div>
          {selectedOption === "tasks" && (
            <Button onClick={handleAddTask}>Add Task Type</Button>
          )}
          {selectedOption === "projects" && (
            <Button onClick={handleAddProjectStatus}>Add Project Status</Button>
          )}
          {selectedOption === "role" && (
            <Button onClick={handleAddRole}>Add Role</Button>
          )}
          {selectedOption === "department" && (
            <Button onClick={handleAddDepartment}>Add Department</Button>
          )}
        </div>
      </div>

      {taskType && (
        <DataTable
          columns={headers}
          rows={taskType}
          remove
          deleteItem={(i) => {
            setShowConfirmation(true);
            setDeleteIndex(i);
          }}
          pageConfig={page}
          pageChanged={handleChangePage}
          rowsChanged={handleChangeRowsPerPage}
        />
      )}
      {projectStatusType && (
        <DataTable
          columns={projectHeaders}
          rows={projectStatusType}
          remove
          deleteItem={(i) => {
            setShowConfirmation(true);
            setDeleteIndex(i);
          }}
          pageConfig={page}
          pageChanged={handleChangePage}
          rowsChanged={handleChangeRowsPerPage}
        />
      )}

      {roles && (
        <DataTable
          columns={roleHeaders}
          rows={roles}
          remove
          deleteItem={(i) => {
            setShowConfirmation(true);
            setDeleteIndex(i);
          }}
          pageConfig={page}
          pageChanged={handleChangePage}
          rowsChanged={handleChangeRowsPerPage}
        />
      )}

      {department && (
        <DataTable
          columns={departmentHeaders}
          rows={department}
          remove
          deleteItem={(i) => {
            setShowConfirmation(true);
            setDeleteIndex(i);
          }}
          pageConfig={page}
          pageChanged={handleChangePage}
          rowsChanged={handleChangeRowsPerPage}
        />
      )}

      {addRole && (
        <div className="add-section">
          <h2 className="add-section-header">Add Role</h2>
          <form onSubmit={handleAddNewRole}>
            <div>
              <TextField
                id="role"
                label="Add Role"
                required
                variant="outlined"
                value={newRole}
                onChange={handleRoleChange}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button"
            >
              Add Role
            </Button>
          </form>
        </div>
      )}

      {addDepartment && (
        <div className="add-section">
          <h2 className="add-section-header">Add Department</h2>
          <form onSubmit={handleAddNewDepartment}>
            <div>
              <TextField
                id="department"
                label="Add Department"
                required
                variant="outlined"
                value={newDepartment}
                onChange={handleDepartmentChange}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button"
            >
              Add Department
            </Button>
          </form>
        </div>
      )}

      {addProjectStatus && (
        <div className="add-section">
          <h2 className="add-section-header">Add project status</h2>
          <form onSubmit={handleProjectStatusSubmit}>
            <div>
              <TextField
                id="project-status"
                label="Add project status"
                required
                variant="outlined"
                value={projectStatus.project_status_name}
                onChange={handlePrjectStatusChange}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button"
            >
              Add project status
            </Button>
          </form>
        </div>
      )}
      {addTaskType && (
        <div className="add-section">
          <h2 className="add-section-header">Add task type</h2>
          <form onSubmit={handleTaskSubmit}>
            <div>
              <TextField
                id="task-status"
                label="Add task type"
                required
                variant="outlined"
                value={task_name}
                onChange={handleTaskStatusChange}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button"
            >
              Add task type
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Admin;
