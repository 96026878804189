import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Grid, MenuItem, Button, TextField } from "@mui/material";
import Toast from "../Utils/Toast";
import { ApiEndPoints } from "../ApiEndPoints";
import "./Task.scss";
import { Button_TEXTS, DefaultRowsPerPage } from "../../constants";
import DataTable from "../DataTable/DataTable";

const Task = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId");
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const [projectStatus, setProjectStatus] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [data, setData] = useState(null);
  const [projectIds, setProjectIds] = useState();
  const user_ID = roleId === 1 ? 0 : userId;
  const header = [
    { key: "task_description", value: "Task Description" },
    { key: "project_name", value: "Project Name" },
  ];
  const [page, setPage] = useState({
    totalRows: 0,
    currentPage: 1,
    rowsPerPage: DefaultRowsPerPage,
  });

  function getAllProject() {
    axios
      .get(
        `${ApiEndPoints.getProjectByUserId}?page=1&page_size=1000&employee_id=${user_ID}&sort_by=asce`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        const projects = res.data.data.projects;
        setProjectIds(projects);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getAllProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTasks = () => {
    setData(null);
    setShowToast(false);
    const url = `${ApiEndPoints.getTasks}page=${page.currentPage}&page_size=${page.rowsPerPage}&user_id=${userId}&project_id=${projectStatus}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const tasks = res.data.data.tasks;

        if (res.data.code === 200) {
          setData(tasks);
          setPage({ ...page, totalRows: res.data.data.total_tasks });
        } else {
          setShowToast(true);
          setType("error");
          setMessage("No Data Found");
        }
      })
      .catch((err) => {
        setShowToast(true);
        setType("error");
        setMessage("No Data Found");
      });
  };

  useEffect(() => {
    if (isMounted) {
      getTasks();
    } else {
      setIsMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.rowsPerPage, page.currentPage, projectStatus]);

  const handleChangeProjectStatus = (event) => {
    setProjectStatus(event.target.value);
  };

  const handleChangePage = (num) => {
    setPage({ ...page, currentPage: num });
  };

  const handleChangeRowsPerPage = (row) => {
    setPage({ ...page, rowsPerPage: row, currentPage: 1 });
  };

  return (
    <div className="task-section">
      <div className="header-buttons">
        <p>All Task</p>

        <Link to="/dashboard/addtask">
          <Button variant="contained" sx={{ mt: 3, mb: 2 }}>
            {Button_TEXTS.addTask}
          </Button>
        </Link>
      
      </div>
      <hr/>
    
      <Grid container spacing={2} className="project-dropdown">
   
        <Grid item xs={10} sm={6} lg={3}>
          <TextField
            select
            id="projectStatus"
            fullWidth
            name="projectStatus"
            value={projectStatus || ""}
            onChange={handleChangeProjectStatus}
            label="Select Project"
          >
            <MenuItem value="" disabled>
              Select Project
            </MenuItem>
            {projectIds &&
              projectIds.map((project) => (
                <MenuItem key={project.project_id} value={project.project_id}>
                  {project.project_name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>

      <div className="table-section">
        <DataTable
          columns={header}
          rows={data}
          pageConfig={page}
          pageChanged={handleChangePage}
          rowsChanged={handleChangeRowsPerPage}
        />
      </div>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default Task;
