import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, Link } from "react-router-dom";
import { ApiEndPoints } from "../ApiEndPoints";
import Toast from "../Utils/Toast";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./ForgotPassword.scss";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [showToast, setShowToast] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [type, setType] = React.useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [data, setData] = React.useState({
    email: "",
    otp: "",
    password: "",
  });

  const [timer, setTimer] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [reSendOtp, setReSendOtp] = useState(false);

  useEffect(() => {
    if (timer === 0) {
      setOtpSent(false);
      setReSendOtp(true);
    } else if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const otpTimer = () => {
    setTimer(90);
  };

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    otp: "",
  });

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!data.email || !emailRegex.test(data.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));
    return true;
  };

  const validateOTP = () => {
    const otpRegex = /^\d{4}$/;
    if (!data.otp || !otpRegex.test(data.otp)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        otp: "Please enter a valid 4-digit OTP.",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      otp: "",
    }));
    return true;
  };

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!data.password || !passwordRegex.test(data.password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must contain minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: "",
    }));
    return true;
  };

 

  const getOTP = () => {
    if (validateEmail()) {
      console.log("otp sent");
      fetch(ApiEndPoints.getOtpForForgotPassword, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: data.email }),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            otpTimer();
            setOtpSent(true);
          }
        })
        .catch((error) => {});
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let trimmedValue = value.trim();
    setData((prevState) => ({ ...prevState, [name]: trimmedValue }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowToast(false);
    if (validateEmail() && validateOTP() && validatePassword()) {
      fetch(ApiEndPoints.forgotPassword, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            setTimeout(() => {
              navigate("/");
            }, 300);
          }
        })
        .catch((error) => {});
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="forgot-password-section">
      <div>
        <Link to="/" className="backarrow">
          <ChevronLeftIcon /> Back
        </Link>
      </div>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box sx={{ mt: 3, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={data.email}
                  helperText={
                    <span className="helper-text">{errors.email}</span>
                  }
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <button
                  onClick={getOTP}
                  disabled={otpSent}
                  className={`submit-button ${otpSent ? "disabled" : ""}`}
                >
                  {reSendOtp ? "Resend OTP" : "Get OTP"}
                </button>
              </Grid>
              <Grid item xs={12}>
                {timer > 0 && (
                  <h4 className="helper-text">
                    Enter the OTP within {timer} seconds
                  </h4>
                )}
              </Grid>

              <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="otp"
                    label="Enter OTP _ _ _ _"
                    type="password"
                    id="otp"
                    helperText={
                      <span className="helper-text">{errors.otp}</span>
                    }
                    value={data.otp}
                    onChange={handleChange}
                    autoComplete="current-password"
                    inputProps={{ maxLength: 4 }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    name="password"
                    id="password"
                    helperText={
                      <span className="helper-text">{errors.password}</span>
                    }
                    value={data.password}
                    onChange={handleChange}
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <button type="submit" className="submit-button">
                    Reset Password
                  </button>
                </form>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
}
