import React, { useState, useEffect } from "react";
import { Grid, Card, Typography } from "@mui/material";
import "./Home.scss";
import { ApiEndPoints } from "../ApiEndPoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [data, setData] = useState();
  const accessToken = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const isUser = roleId === 3 ? true : false;

  const getUsers = () => {
    const url = `${ApiEndPoints.getDashBoardDetails}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const users = res.data.data;
        setData(users);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className="admin-home">
      {isUser && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            onClick={() => navigate("/dashboard/userProjects")}
          >
            <Card className="card-item">
              <Typography>Projects </Typography>
              {data && <h3>{data.total_projects}</h3>}
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            onClick={() => navigate("/dashboard/reports")}
          >
            <Card className="card-item">
              <Typography>Reports</Typography>
            </Card>
          </Grid>
        </>
      )}

      {!isUser && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            onClick={() => navigate("/dashboard/clients")}
          >
            <Card className="card-item">
              <Typography>Clients </Typography>
              {data && <h3>{data.total_clients}</h3>}
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            onClick={() => navigate("/dashboard/users")}
          >
            <Card className="card-item">
              <Typography>Employees </Typography>
              {data && <h3>{data.total_users}</h3>}
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            onClick={() => navigate("/dashboard/projects")}
          >
            <Card className="card-item">
              <Typography>Projects </Typography>
              {data && <h3>{data.total_projects}</h3>}
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            onClick={() => navigate("/dashboard/reports")}
          >
            <Card className="card-item">
              <Typography>Reports</Typography>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Home;
