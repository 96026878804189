import React, { useState } from "react";
import Toast from "../Utils/Toast";
import { TextField, Button, Box, IconButton } from "@mui/material";
import "./ChangePassword.scss";
import { useNavigate } from "react-router-dom";
import { ApiEndPoints } from "../ApiEndPoints";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button_TEXTS } from "../../constants";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const userId = sessionStorage.getItem("userId");
  const accessToken = sessionStorage.getItem("accessToken");
  const [formData, setFormData] = React.useState({
    old_password: "",
    new_password: "",
  });
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [errors, setErrors] = useState({
    old_password: "",
    new_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateNewPassword = () => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!formData.new_password || !passwordRegex.test(formData.new_password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        new_password:
          "Password must contain minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      new_password: "",
    }));
    return true;
  };

  const validateOldPasswor = () => {
    if (!formData.old_password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        old_password: "Please enter the Password",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      old_password: "",
    }));
    return true;
  };

  const handleTogglePassword = () => {
    setShowOldPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowToast(false);
    const url = `${ApiEndPoints.chanePassword}/${userId}`;
    if (validateOldPasswor() && validateNewPassword()) {
      fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            navigate("/");
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="changePassword-section"
    >
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Old Password"
            name="old_password"
            autoFocus
            value={formData.old_password}
            helperText={
              <span className="helper-text">{errors.old_password}</span>
            }
            onChange={handleChange}
            type={showOldPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="new_password"
            helperText={
              <span className="helper-text">{errors.new_password}</span>
            }
            label="New Password"
            value={formData.new_password}
            onChange={handleChange}
            type={showNewPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleToggleNewPassword} edge="end">
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            className="submit-btn"
            variant="contained"
          >
            {Button_TEXTS.changePassword}
          </Button>
        </Box>
      </Box>
      {showToast && <Toast type={type} message={message} />}
    </Container>
  );
};

export default ChangePassword;
