import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import Dashboard from './components/DashBoard/DashBoard';
import SignIn from './components/SignIn/SignIn';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Users from './components/Users/Users';
import AddUser from './components/AddUser/AddUser';
import Clients from './components/Clients/Clients';
import Home from './components/Home/Home';
import AddClients from './components/AddClients/AddClients'; 
import Project from './components/Project/Project';
import AddProject from './components/AddProject/AddProject';
import AddTask from  './components/AddTask/AddTask' 
import Task from './components/Task/Task';
import EditEmployee from './components/EditEmployee/EditEmploee';
import EditProject from './components/EditProject/EditProject';
import EditClient from './components/EditClient/EditClient';
import TimeSheet from './components/TimeSheet/TimeSheet';
import ChangeEmail from './components/ChangeEmail/ChangeEmail';
import ManageTimeSheet from './components/ManageTimesheet/ManageTimeSheet';
import ManageAttendence from './components/ManageEmployeeAttendence/ManageAttendence';
import Report from './components/Report/Report';
import Admin from './components/Admin/Admin';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Attendence from './components/Attendence/Attendence';


const router = createBrowserRouter([
  {
    path: "",
    element: <SignIn/>,
  },
  {
    path: "forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "changePassword",
        element: <ChangePassword />,
      },
      {
        path: "changeEmail",
        element: <ChangeEmail />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "addUser",
        element: <AddUser />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "addClients",
        element: <AddClients/>,
      },
      {
        path: "projects",
        element: <Project/>,
      },
      {
        path: "addProject",
        element: <AddProject/>,
      },
      {
        path: "editEmployee",
        element:<EditEmployee/>
      },
      {
        path: "editProject",
        element: <EditProject/>,
      },
      {
        path: "editClient",
        element: <EditClient/>,
      },
      {
        path: "task",
        element:<Task/>
      },
      {
        path: "addtask",
        element:<AddTask/>
      },
      {
        path: "attendence",
        element:<Attendence/>
      },
      {
        path: "manageAttendence",
        element:<ManageAttendence/>
      },
      {
        path: "timesheet",
        element:<TimeSheet/>
      },
      {
        path: "manageTimesheet",
        element:<ManageTimeSheet/>
      },
      {
        path: "reports",
        element: <Report/>,
      },
      {
        path: "admin",
        element: <Admin/>,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

