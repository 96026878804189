import React, { useState } from "react";
import { TextField, Grid, Button, MenuItem } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./AddClients.scss";
import Toast from "../Utils/Toast";
import { ApiEndPoints } from "../ApiEndPoints";
import { useNavigate, Link } from "react-router-dom";
import { Button_TEXTS, TEXT_MSGS } from "../../constants";
import { CountryList } from "../../constants";

const AddClients = () => {
  const [formData, setFormData] = useState({
    client_name: "",
    email: "",
    country: "",
    telephone_1: "",
    address: "",
    state: "",
    city: "",
    pin_code: "",
    website: "",
    notes: "",
    contact_person_name: "",
    is_active: true,
  });
  const [errors, setErrors] = useState({
    client_name: "",
    contact_person_name: "",
    email: "",
    telephone_1: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateClientName = () => {
    const firstNameRegex = /^[a-zA-Z\s]*$/;
    setFormData((prevData) => ({
      ...prevData,
      client_name: formData.client_name.trim(),
    }));
    if (!formData.client_name || !firstNameRegex.test(formData.client_name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        client_name: "Client name must contain only letters",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      client_name: "",
    }));
    return true;
  };

  const validateContactPersonName = () => {
    setFormData((prevData) => ({
      ...prevData,
      contact_person_name: formData.contact_person_name.trim(),
    }));
    const firstNameRegex = /^[a-zA-Z\s]*$/;
    if (
      !formData.contact_person_name ||
      !firstNameRegex.test(formData.contact_person_name)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact_person_name: "Name must contain only letters",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      contact_person_name: "",
    }));
    return true;
  };

  //Validation for email
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));
    return true;
  };

  const validateMobileNumber = () => {
    const mobileRegex = /^\+?[0-9]{10,13}$/;
    if (!formData.telephone_1 || !mobileRegex.test(formData.telephone_1)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        telephone_1:
          "Mobile number must be 10 to 13 digits, optionally starting with a '+' for the country code",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      telephone_1: "",
    }));
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      validateClientName() &&
      validateContactPersonName() &&
      validateEmail() &&
      validateMobileNumber()
    ) {
      setShowToast(false);
      const accessToken = sessionStorage.getItem("accessToken");
      const apiUrl = ApiEndPoints.addClient;
      fetch(apiUrl, {
        method: "POST",

        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            setTimeout(() => {
              navigate("/dashboard/clients");
            }, 500);
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <div className="add-client">
      <Link to="/dashboard/clients" className="navigation-section">
        <div className="client-list-backarrow">
          <ChevronLeftIcon /> {TEXT_MSGS.back}
        </div>
      </Link>
      <form onSubmit={handleSubmit} className="form-wrapper">
        <Grid
          container
          spacing={2}
          className="addClient-section"
          columnSpacing={{ xs: 6, sm: 6, md: 6, lg: 6 }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Client Name"
              fullWidth
              required
              helperText={
                <span className="helper-text">{errors.client_name}</span>
              }
              name="client_name"
              value={formData.client_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact person Name"
              fullWidth
              required
              name="contact_person_name"
              helperText={
                <span className="helper-text">
                  {errors.contact_person_name}
                </span>
              }
              value={formData.contact_person_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact person email"
              fullWidth
              required
              name="email"
              helperText={<span className="helper-text">{errors.email}</span>}
              value={formData.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact person mobile number"
              fullWidth
              required
              helperText={
                <span className="helper-text">{errors.telephone_1}</span>
              }
              name="telephone_1"
              value={formData.telephone_1}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Website"
              fullWidth
              name="website"
              value={formData.website}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="country-select"
              select
              required
              label="Select a country"
              value={formData.country}
              name="country"
              onChange={handleInputChange}
              fullWidth
            >
              {CountryList.map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="State"
              fullWidth
              required
              name="state"
              value={formData.state}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="City"
              fullWidth
              name="city"
              value={formData.city}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              fullWidth
              required
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Pin Code"
              fullWidth
              required
              name="pin_code"
              value={formData.pin_code}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Button type="submit" sx={{ mt: 5 }}>
          {Button_TEXTS.addClient}
        </Button>
      </form>

      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default AddClients;
