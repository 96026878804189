import React, { useState } from "react";
import {
  TextField,
  Button,
  IconButton,
  Box,
  Container,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, Link } from "react-router-dom";
import Toast from "../Utils/Toast";
import { ApiEndPoints } from "../ApiEndPoints";
import "./SignIn.scss";
import Viewwiserlogo from "../../assets/images/Viewwiser.png";
import { Version } from "../../constants";

export default function SignIn() {
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value.trim();
    if (name === "email") {
      trimmedValue = value.trim().toLowerCase();
    }
    setFormData((prevState) => ({ ...prevState, [name]: trimmedValue }));
  };

  //Validation for email
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));
    return true;
  };

  // Validation for password
  const validatePassword = () => {
    if (!formData.password || formData.password.length < 6) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters long",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: "",
    }));
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowToast(false);
    if (validateEmail() && validatePassword()) {
      const url = ApiEndPoints.signIn;
      fetch(url, {
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const accessToken = data.data.access_token;
            const userId = data.data.user_id;
            const name = `${data.data.first_name} ${data.data.last_name}`;
            sessionStorage.setItem("userId", userId);
            sessionStorage.setItem("accessToken", accessToken);
            sessionStorage.setItem("name", name);
            navigate("/dashboard/home");

            if (data.data.role[0] === "user") {
              sessionStorage.setItem("roleId", 3);
            } else if (data.data.role[0] === "admin") {
              sessionStorage.setItem("roleId", 1);
            } else if (data.data.role[0] === "manager") {
              sessionStorage.setItem("roleId", 2);
            }
          }
        })
        .catch((error) => {});
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="sign-in">
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="main-logo-section">
            <img src={Viewwiserlogo} alt="Logo" />
            <h2>Sign In</h2>
          </div>

          <form onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              margin="normal"
              sx={{ mb: 3 }}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={formData.email}
              autoComplete="email"
              variant="outlined"
              onChange={handleChange}
              helperText={<span className="helper-text">{errors.email}</span>}
            />

            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              name="password"
              value={formData.password}
              id="password"
              onChange={handleChange}
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                <span className="helper-text">{errors.password}</span>
              }
            />
            <Button variant="contained" fullWidth type="submit">
              Sign In
            </Button>
            <Link
              to="forgotPassword"
              variant="body3"
              className="forgot-password"
            >
              Forgot password ?
            </Link>
            <p style={{ textAlign: "center" }}>Version : {Version}</p>
          </form>
        </Box>
        {showToast && <Toast type={type} message={message} />}
      </Container>
    </div>
  );
}
