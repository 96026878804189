import React, { useEffect, useState } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./EditEmployee.scss";
import Toast from "../Utils/Toast";
import { ApiEndPoints } from "../ApiEndPoints";
import { Button_TEXTS } from "../../constants";

const EditEmployee = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const location = useLocation();
  const { state } = location;
  const datas = state && state.singleProp;
  const [updated, setUpdated] = useState(false);
  const [originalData, setOriginalData] = useState(datas);

  const navigate = useNavigate();
  const [roles, setRoles] = useState();
  const [formData, setFormData] = useState({
    first_name: datas.first_name,
    last_name: datas.last_name,
    gender: datas.gender,
    country_nationality: datas.country_nationality,
    email: datas.email,
    role: [datas.user_role],
  });
  const role = datas.user_role;
  const dob = convertTimestampToDate(datas.dob);

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    email: "",
    contact_number: "",
  });

  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;
    var updatedValue = value.trim();

    if (name === "dob") {
      var dateObject = new Date(value);
      updatedValue = dateObject.getTime();
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  useEffect(() => {
    getRoles();
    setOriginalData(datas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateFirstName = () => {
    const firstNameRegex = /^[a-zA-Z\s]*$/;

    if (!formData.first_name || !firstNameRegex.test(formData.first_name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "First name must contain only letters",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      first_name: "",
    }));
    return true;
  };

  const validateLastName = (fName) => {
    const lastNameRegex = /^[a-zA-Z\s]*$/;

    if (!fName || !lastNameRegex.test(fName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "Last name must contain only letters",
      }));
      return false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      last_name: "",
    }));

    return true;
  };

  //Validation for email
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const trimmedEmail = formData.email.trim();
    console.log(trimmedEmail);

    setFormData((prevData) => ({
      ...prevData,
      email: trimmedEmail,
    }));

    if (!trimmedEmail || !emailRegex.test(trimmedEmail)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      return false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));

    return true;
  };

  const getRoles = () => {
    axios
      .get(ApiEndPoints.getRoles, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const roles = res.data.data;
        const role = roles.map((e) => {
          return [e.role_name];
        });
        setRoles(role);
      })
      .catch((error) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      validateLastName(formData.last_name.trim()) &&
      validateEmail() &&
      validateFirstName()
    ) {
      console.log("formData ", formData);
      const editedData = Object.keys(formData).reduce((acc, key) => {
        if (formData[key] !== originalData[key]) {
          acc[key] = formData[key];
        }
        return acc;
      }, {});
      console.log("editedData ", editedData);
      setShowToast(false);
      const userId = datas.user_id;
      const url = `${ApiEndPoints.updateUser}${userId}`;
      fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            setTimeout(() => {
              navigate("/dashboard/users");
            }, 800);
          }
        })
        .catch((error) => {});
    }
  };
  return (
    <div className="edit-employee">
      <Link to="/dashboard/users" className="navigation-section">
        <div className="edit-list-backarrow">
          <ChevronLeftIcon /> Back
        </div>
      </Link>
      <form onSubmit={handleSubmit} className="form-wrapper">
        <Grid container spacing={2}  className="editEmployee-section"
            columnSpacing={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              required
              fullWidth
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              helperText={
                <span className="helper-text">{errors.first_name}</span>
              }
              id="First_Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              id="last_name"
              fullWidth
              required
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              helperText={
                <span className="helper-text">{errors.last_name}</span>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              helperText={<span className="helper-text">{errors.email}</span>}
              onChange={handleChange}
              margin="normal"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Birth"
              name="dob"
              type="date"
              disabled
              value={dob}
              onChange={handleChange}
              helperText={<span className="helper-text">{errors.dob}</span>}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" className="role-box">
              <InputLabel htmlFor="role">{role}</InputLabel>
              <Select label="Role" name="role" onChange={handleChange}>
                {roles?.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                row
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="gender-block"
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Country"
              fullWidth
              name="country_nationality"
              value={formData.country_nationality}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <button
          type="submit"
          disabled={!updated}
          className={`button-${updated ? "enabled" : "disabled"}`}
        >
          {Button_TEXTS.updateUser}
        </button>
      </form>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default EditEmployee;
