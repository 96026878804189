import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, Link } from "react-router-dom";
import Home from "../../assets/images/Home.svg";
import Report from "../../assets/images/report.svg";
import Task from "../../assets/images/task.svg";
import Project from "../../assets/images/projects.svg";
import Attendence from "../../assets/images/attendence.svg";
import TimeSheet from "../../assets/images/timesheet.svg";
import EmpTimesheet from "../../assets/images/timesheet-2.svg";
import Users from "../../assets/images/users.svg";
import Client from "../../assets/images/clients.svg";
import Admin from "../../assets/images/admin.svg";
import "./ListItem.scss";

const ListItem = () => {
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  return (
    <div className="list-item-section">
      <NavLink to="/dashboard/home" activeclassname="activeLink">
        <ListItemButton>
          <ListItemIcon>
            <img src={Home} alt="home" />
          </ListItemIcon>
          <ListItemText className="list_items" primary="Home" />
        </ListItemButton>
      </NavLink>

      <NavLink to="attendence">
        <ListItemButton>
          <ListItemIcon>
            <img src={Attendence} alt="attendence" />
          </ListItemIcon>
          <ListItemText className="list_items" primary="Attendence" />
        </ListItemButton>
      </NavLink>

      <Link to="timesheet">
        <ListItemButton>
          <ListItemIcon>
            <img src={TimeSheet} alt="timesheet" />
          </ListItemIcon>

          <ListItemText className="list_items" primary="TimeSheet" />
        </ListItemButton>
      </Link>

      {roleId === 1 && (
        <>
          <NavLink to="users" activeclassname="activeLink">
            <ListItemButton>
              <ListItemIcon>
                <img src={Users} alt="users" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Users" />
            </ListItemButton>
          </NavLink>

          <Link to="clients" activeclassname="activeLink">
            <ListItemButton>
              <ListItemIcon>
                <img src={Client} alt="client" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Clients" />
            </ListItemButton>
          </Link>

          <Link to="projects" activeclassname="activeLink">
            <ListItemButton>
              <ListItemIcon>
                <img src={Project} alt="project" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Projects" />
            </ListItemButton>
          </Link>
          <Link to="task" activeclassname="activeLink">
            <ListItemButton>
              <ListItemIcon>
                <img src={Task} alt="task" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Tasks" />
            </ListItemButton>
          </Link>

          <Link to="manageTimesheet">
            <ListItemButton>
              <ListItemIcon>
                <img src={EmpTimesheet} alt="Emptimesheet" />
              </ListItemIcon>

              <ListItemText className="list_items" primary="TimeSheets" />
            </ListItemButton>
          </Link>

          <Link to="reports">
            <ListItemButton>
              <ListItemIcon>
                <img src={Report} alt="report" style={{ height: "18px" }} />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Reports" />
            </ListItemButton>
          </Link>

          <Link to="admin">
            <ListItemButton>
              <ListItemIcon>
                <img src={Admin} alt="admin" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Admin" />
            </ListItemButton>
          </Link>
        </>
      )}

      {roleId === 2 && (
        <>
          <NavLink to="users" activeclassname="activeLink">
            <ListItemButton>
              <ListItemIcon>
                <img src={Users} alt="users" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Users" />
            </ListItemButton>
          </NavLink>

          <Link to="projects" activeclassname="activeLink">
            <ListItemButton>
              <ListItemIcon>
                <img src={Project} alt="project" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Projects" />
            </ListItemButton>
          </Link>

          <Link to="task" activeclassname="activeLink">
            <ListItemButton>
              <ListItemIcon>
                <img src={Task} alt="task" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Tasks" />
            </ListItemButton>
          </Link>

          <Link to="manageTimesheet">
            <ListItemButton>
              <ListItemIcon>
                <img src={EmpTimesheet} alt="Emptimesheet" />
              </ListItemIcon>
              <div className="list_items">
                <ListItemText primary="Employee" />
                <ListItemText primary="Timesheet" />
              </div>
            </ListItemButton>
          </Link>

          <Link to="reports">
            <ListItemButton>
              <ListItemIcon>
                <img src={Report} alt="report" style={{ height: "18px" }} />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Reports" />
            </ListItemButton>
          </Link>
        </>
      )}

      {roleId === 3 && (
        <>
          <Link to="projects">
            <ListItemButton>
              <ListItemIcon>
                <img src={Project} alt="project" />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Projects" />
            </ListItemButton>
          </Link>

          <Link to="task">
            <ListItemButton>
              <ListItemIcon>
                <img src={Task} alt="task" />
              </ListItemIcon>
              <ListItemText className="list_items" primary=" Task" />
            </ListItemButton>
          </Link>

          <Link to="reports">
            <ListItemButton>
              <ListItemIcon>
                <img src={Report} alt="report" style={{ height: "18px" }} />
              </ListItemIcon>
              <ListItemText className="list_items" primary="Reports" />
            </ListItemButton>
          </Link>
        </>
      )}
    </div>
  );
};

export default ListItem;
