import React, { useState } from "react";
import Toast from "../Utils/Toast";
import { useNavigate } from "react-router-dom";
import { ApiEndPoints } from "../ApiEndPoints";
import "./ChangeEmail.scss";
import { TextField } from "@mui/material";
import { Button_TEXTS } from "../../constants";

const ChangeEmail = () => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const userId = sessionStorage.getItem("userId");
  const accessToken = sessionStorage.getItem("accessToken");
  const [otp, setOtp] = useState();
  const [formData, setFormData] = useState({
    user_id: parseInt(userId),
    new_email: "",
  });
const [getOtp, setGetOtp]=useState(false);
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      new_email: e.target.value,
    }));
  };

  const getOTP = async (e) => {
    e.preventDefault();
    setShowToast(false);
    if (validateEmail()) {
      const url = ApiEndPoints.changeEmailGetOtp;
      fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          const data = await response.json();
          console.log(data);

          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            setGetOtp(true);
          }
        })
        .catch((error) => {});
    }
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const [errors, setErrors] = useState({
    email: "",
    otp: "",
  });

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.new_email || !emailRegex.test(formData.new_email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please Enter a valid email",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));
    return true;
  };

  const validateOTP = () => {
    const otpRegex = /^\d{4}$/;
    if (!otp || !otpRegex.test(otp)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        otp: "Please enter a valid 4-digit OTP.",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      otp: "",
    }));
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowToast(false);

    if (validateEmail() && validateOTP()) {
      const url = ApiEndPoints.changeEmail;
      fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          user_id: formData.user_id,
          new_email: formData.new_email,
          otp: otp,
        }),
      })
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            setTimeout(() => {
              navigate("/");
            }, 500);
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <div className="container">
      <div className="form-wrapper">
        <TextField
          className="input-field"
          fullWidth
          type="email"
          margin="normal"
          id="email"
          label="Enter new email"
          name="email"
          autoComplete="email"
          autoFocus
          value={formData.new_email}
          helperText={<span className="helper-text">{errors.email}</span>}
          onChange={handleChange}
        />
        <button className="btn-get-otp" onClick={getOTP} disabled={getOtp}>
          {Button_TEXTS.getOtp}
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-wrapper">
          <TextField
            margin="normal"
            fullWidth
            name="otp"
            className="input-field"
            label="Enter OTP _ _ _ _"
            type="password"
            id="otp"
            helperText={<span className="helper-text">{errors.otp}</span>}
            onChange={handleOtpChange}
            autoComplete="current-password"
            inputProps={{ maxLength: 4 }}
          />
          <button className="btn-reset-password" type="submit" >
            {Button_TEXTS.changeEmail}
          </button>
        </div>
      </form>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default ChangeEmail;
