import React from "react";
import "./Popup.scss";
import {Button_TEXTS, TEXT_MSGS} from '../../constants'

const Popup = ({ isOpen, onClose, onConfirm }) => {
  return (
    isOpen && (
      <div className="popup-overlay">
        <div className="popup">
          <div className="popup-content">
            <h3>{TEXT_MSGS.cannotDelete}</h3>
            <div className="button-container">
            <button className="confirm-button" onClick={onConfirm}>{Button_TEXTS.popUpConfirm}</button>
              <button className="cancel-button" onClick={onClose}>{Button_TEXTS.popUpCancel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Popup;
