import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import axios from "axios";
import { ApiEndPoints } from "../ApiEndPoints";
import Toast from "../Utils/Toast";
import { useNavigate } from "react-router-dom";
import "./Profile.scss";

export function AccountMenu() {
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("accessToken");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showToast, setShowToast] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [type, setType] = React.useState();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleClick(0);
    navigate("/dashboard/changePassword");
  };

  const handleEmailChange = () => {
    navigate("/dashboard/changeEmail");
    handleClick(0);
  };

  const [data, setData] = useState();
  const userId = sessionStorage.getItem("userId");

  const getUsers = () => {
    const url = `${ApiEndPoints.getUserById}/${userId}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const users = res.data.data;
        setData(users);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = () => {
    axios
      .get(ApiEndPoints.signOut, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        navigate("/");
        setShowToast(true);
        setType("success");
        setMessage(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  const first_name = sessionStorage.getItem("name");
  const first_letter_of_Name = first_name
    ? first_name.trim() !== ""
      ? first_name.charAt(0).toUpperCase()
      : ""
    : "";

  return (
    <div className="profile-section">
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar sx={{ width: 32, height: 32, backgroundColor: "#102C57" }}>
          {first_letter_of_Name}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          {data && (
            <div className="user-info">
              <p>
                {data.first_name} {data.last_name}
              </p>
              <span>{data.email}</span>
              <br />
              <span> Role : {data.role[0]}</span>
              <br />
              <span>{data.contact_number}</span>
              <br />
              <span></span>
              <hr></hr>
            </div>
          )}
        </MenuItem>
        <MenuItem onClick={handleProfile} className="profile-headers" style={{fontSize:'14px'}}>
          <Avatar style={{width:'24px',height:'24px'}}/> Change Password
        </MenuItem>
        <MenuItem onClick={handleEmailChange} style={{fontSize:'14px'}}>
          <Avatar style={{width:'24px',height:'24px'}} /> Change Email
        </MenuItem>

        <Divider />

        <MenuItem onClick={logOut} style={{fontSize:'14px'}}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
}
