import React, { useEffect, useState, useRef } from "react";
import { Grid, TextField, Button, MenuItem } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./AddProjects.scss";
import { ApiEndPoints } from "../ApiEndPoints";
import Toast from "../Utils/Toast";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { List, ListItem, ListItemText } from "@mui/material";
import { Button_TEXTS, TEXT_MSGS } from "../../constants";

const AddProject = () => {
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("accessToken");
  const [formData, setFormData] = useState({
    project_name: "",
    client_id: "",
    project_code: "",
    project_manager_id: "",
    project_description: "",
    start_date: "",
    due_date: "",
    duration: 30,
    timesheet_approver: "",
    employee_ids: [],
  });

  const [errors, setErrors] = useState({
    project_name: "",
    start_date: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [isMountedUser, setIsMountedUser] = useState(false);
  const [managers, setManagers] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [searchedClients, setSearchedClients] = useState([]);

  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const getManagers = () => {
    axios
      .get(ApiEndPoints.getManagers, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setManagers(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (isMountedUser) {
      fetchUsers();
    } else {
      getManagers();
      setIsMountedUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name.includes("_id")) {
      formattedValue = parseInt(value, 10);
    }

    if (name === "start_date" || name === "due_date") {
      const currentDate = new Date();
      const hours = currentDate.getUTCHours();
      const minutes = currentDate.getUTCMinutes();
      const seconds = currentDate.getUTCSeconds();
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}Z`;
      const dateTimeString = `${value}T${formattedTime}`;
      console.log(dateTimeString, "date");
      formattedValue = dateTimeString;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const validateProjectName = () => {
    const firstNameRegex = /^[a-zA-Z\s]*$/;
    setFormData((prevData) => ({
      ...prevData,
      project_name: formData.project_name.trim(),
    }));

    if (!formData.project_name || !firstNameRegex.test(formData.project_name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        project_name: "Project name must contain only letters",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      project_name: "",
    }));
    return true;
  };

  const validateDates = () => {
    const date1 = new Date(formData.start_date);
    const date2 = new Date(formData.due_date);

    if (date1 < date2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        start_date: "",
      }));
      return true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        start_date: "Start  date is Less than the End date",
      }));
      return false;
    }
  };

  const handleSubmit = (event) => {
    setShowToast(false);
    event.preventDefault();
    if (validateProjectName() && validateDates()) {
      const url = ApiEndPoints.addProject;
      fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            setTimeout(() => {
              navigate("/dashboard/projects");
            }, 300);
          }
        })
        .catch((error) => {});
    }
  };

  const SelectClient = () => {
    const handleSelect = (event, value) => {
      setFormData({
        ...formData,
        client_id: value.client_id,
      });
      setSelectedClient(value.client_name);
      setSearchedClients([]);
    };

    const handleClientSearch = async (value) => {
      const accessToken = sessionStorage.getItem("accessToken");

      const url = `${ApiEndPoints.searchClients}?page=1&page_size=10&search_query=${value}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          const users = res.data.data.clients;
          setSearchedClients(users);
        })
        .catch((err) => console.log(err));
    };

    return (
      <div>
        <TextField
          label="Search Client"
          fullWidth
          variant="outlined"
          onChange={(event) => {
            const newInputValue = event.target.value;
            handleClientSearch(newInputValue);
          }}
        />
        {selectedClient && <div>Selected: {selectedClient}</div>}
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
          <List>
            {searchedClients.map((result) => (
              <ListItem
                key={result.id}
                onClick={() => handleSelect(null, result)}
              >
                <ListItemText primary={result.client_name} />
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    );
  };

  const handleFilter = (event) => {
    setFilter(event.target.value);
    setDropdownVisible(true);
  };
  const updateEmployeeIds = (id) => {
    setFormData((prevState) => ({
      ...prevState,
      employee_ids: id,
    }));
  };

  const insertUsers = (id) => {
    updateEmployeeIds(id);
  };

  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      const userIndex = prevSelectedUsers.indexOf(userId);
      if (userIndex === -1) {
        return [...prevSelectedUsers, userId];
      } else {
        const updatedSelectedUsers = [...prevSelectedUsers];
        updatedSelectedUsers.splice(userIndex, 1);
        return updatedSelectedUsers;
      }
    });
    insertUsers(selectedUsers);
  };

  const fetchUsers = async () => {
    const accessToken = sessionStorage.getItem("accessToken");

    const url = `${ApiEndPoints.getUsers}?search_term=${filter}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const users = res.data.data.users;
        setUsers(users);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // To Hide User dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="addProject-section">
      <Link to="/dashboard/projects" className="backarrow">
        <ChevronLeftIcon /> {TEXT_MSGS.back}
      </Link>

      <form onSubmit={handleSubmit} className="form-wrapper">
        <Grid container spacing={2} className="addProject-section"     columnSpacing={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Project Name"
              fullWidth
              required
              name="project_name"
              value={formData.project_name}
              helperText={
                <span className="helper-text">{errors.project_name}</span>
              }
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Project Code"
              fullWidth
              name="project_code"
              required
              value={formData.project_code}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectClient />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              required
              label="Select Manager"
              name="project_manager_id"
              value={formData.project_manager_id}
              onChange={handleInputChange}
            >
              <MenuItem disabled>Select Manager</MenuItem>
              {managers?.map((manager) => (
                <MenuItem key={manager.user_id} value={manager.user_id}>
                  {manager.username}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              name="start_date"
              type="date"
              required
              onChange={handleInputChange}
              fullWidth
              helperText={
                <span className="helper-text">{errors.start_date}</span>
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Due Date"
              name="due_date"
              required
              type="date"
              onChange={handleInputChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Description"
              fullWidth
              name="project_description"
              multiline
              rows={1}
              value={formData.project_description}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className="dropdown" ref={dropdownRef}>
              <input
                type="text"
                placeholder="Select Employees"
                id="myInput"
                onChange={handleFilter}
                className="dropbtn"
                onClick={() => setDropdownVisible(true)}
                style={{
                  position: "absolute",
                  display: "inline-block",
                }}
              />
              <div
                className="dropdown-content"
                id="myDropdown"
                style={{
                  display: dropdownVisible ? "block" : "none",
                  position: "absolute",
                  backgroundColor: "#f6f6f6",
                  minWidth: "230px",
                  overflowY: "auto",
                  maxHeight: "150px",
                  border: "1px solid #ddd",
                  zIndex: 2,
                  top: "70%",
                }}
              >
                {users.map((user) => (
                  <label
                    key={user.user_id}
                    style={{
                      display: "block",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectedUsers.includes(user.user_id)}
                      onChange={() => handleCheckboxChange(user.user_id)}
                      style={{
                        marginRight: "8px",
                      }}
                    />
                    {user.first_name} {user.last_name}
                  </label>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>

        <Button type="submit">{Button_TEXTS.addProject}</Button>
      </form>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default AddProject;
