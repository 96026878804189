/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTable from "../DataTable/DataTable";
import "./Clients.scss";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { ApiEndPoints } from "../ApiEndPoints";
import Toast from "../Utils/Toast";
import Popup from "../PopUp/PopUp";
import { Button_TEXTS, DefaultRowsPerPage } from "../../constants";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import FileOpenIcon from "@mui/icons-material/FileOpen";

const Clients = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientData, setClientData] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState({
    totalRows: 0,
    currentPage: 1,
    rowsPerPage: DefaultRowsPerPage,
  });
  const [searchError, setSearchError] = useState("");
  const [isSearchEnable, setIsSearchEnable] = useState(false);
  const [headers, setHeaders] = useState([
    { key: "client_name", value: "Client Name" },
    { key: "contact_person_name", value: "Contact Person" },
    { key: "email", value: "Email" },
    { key: "city", value: "Address" },
  ]);

  const options = [
    { key: "telephone_1", value: "Contact number" },
    { key: "website", value: "Website" },
    { key: "country", value: "Country" },
    { key: "state", value: "State" },
    { key: "pin_code", value: "Pin code" },
  ];

  const handleCheckboxChange = (obj) => {
    const isChecked = headers.some((item) => item.key === obj.key);
    if (isChecked) {
      setHeaders((prevState) =>
        prevState.filter((item) => item.key !== obj.key)
      );
    } else {
      setHeaders((prevState) => [...prevState, obj]);
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const getClients = () => {
    axios
      .get(
        `${ApiEndPoints.getClients}?page=${page.currentPage}&page_size=${page.rowsPerPage}&search_query=${searchTerm}&sort_by=${sortOrder}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        const clients = res.data.data.clients;
        setClientData(clients);
        setPage({ ...page, totalRows: res.data.data.total_clients });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getClients();
  }, [page.currentPage, page.rowsPerPage,sortOrder]);

  const handleChangePage = (num) => {
    setPage({ ...page, currentPage: num });
  };
  const handleChangeRowsPerPage = (row) => {
    setPage({ ...page, rowsPerPage: row, currentPage: 1 });
  };

  const deleteClient = async (i) => {
    setShowToast(false);
    const id = clientData[i].client_id;
    const url = `${ApiEndPoints.deleteClient}/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          clientData.splice(i, 1);
          setClientData([...clientData]);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    let timer;
    if (searchTerm && isSearchEnable) {
      timer = setTimeout(() => {
        setClientData(null);
        setPage({ ...page, currentPage: 1 });
        getClients();
      }, 2000);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm.length < 2) {
      setSearchError("Search term must be at least 3 characters to search ");
    } else {
      setIsSearchEnable(true);
      setSearchError("");
    }
  };

  const editClient = (i) => {
    const editData = clientData[i];
    navigate("/dashboard/editClient", { state: { singleProp: editData } });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  const handleReportDownload = () => {
    fetch(ApiEndPoints.getClientReport, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Client_Report.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <div className="client-header-wrap">
        <div className="header-text">Clients List</div>

        <div className="wrapper-right-container">
          <div className="search-container">
            <input
              type="text"
              id="searchUser"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search Client"
              className="search-input"
            />
            <SearchIcon style={{ cursor: "pointer" }} />
          </div>
          <div className="dropdown-section">
            <div onClick={toggleDropdown} className="dropDown-button">
              <SettingsIcon />
            </div>
            <p className="helper-text">Add Colums </p>
            {isOpen && (
              <div className="dropdown-content">
                <h5>Add colums</h5>
                <button onClick={handleCloseDropdown}>X</button>

                {options.map((option) => (
                  <label key={option.key} style={{ display: "block" }}>
                    <input
                      type="checkbox"
                      checked={headers.some((item) => item.key === option.key)}
                      onChange={() => handleCheckboxChange(option)}
                    />
                    {option.value}
                  </label>
                ))}
              </div>
            )}
          </div>
          <div className="download-section">
            <div className="download-button" onClick={handleReportDownload}>
              <FileOpenIcon />
            </div>
            <p className="download-button-helper-text">Export to CSV</p>
          </div>
          <Link to="/dashboard/addClients">
            <Button className="add-client-button">
              {Button_TEXTS.addClient}
            </Button>
          </Link>
        </div>
      </div>
      {searchError && <div className="search-error">{searchError}</div>}

      <Popup
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => {
          deleteClient(deleteIndex);
          setShowConfirmation(false);
        }}
      />

      <DataTable
        columns={headers}
        rows={clientData}
        action
        sortOrder={sortOrder}
        toggleSortOrder={toggleSortOrder}
        editItem={editClient}
        deleteItem={(i) => {
          setShowConfirmation(true);
          setDeleteIndex(i);
        }}
        pageConfig={page}
        pageChanged={handleChangePage}
        rowsChanged={handleChangeRowsPerPage}
      />

      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default Clients;
