

export const API_BASE_URL = process.env.REACT_APP_TIMESHEET_API_URL;


  export const ApiEndPoints = {
    signIn:`${API_BASE_URL}/api/v1/users/login`,
    signOut:`${API_BASE_URL}/api/v1/users/logout`, 
    getOtpForForgotPassword:`${API_BASE_URL}/api/v1/users/forgot-password`,
    forgotPassword:`${API_BASE_URL}/api/v1/users/forgot-password`,                        
    chanePassword:`${API_BASE_URL}/api/v1/users/password`,
    changeEmailGetOtp:`${API_BASE_URL}/api/v1/users/edit-email-otp`,
    changeEmail:`${API_BASE_URL}/api/v1/users/edit-email`,
    getDashBoardDetails:`${API_BASE_URL}/api/v1/dashbord`,   
    getRoles:`${API_BASE_URL}/api/v1/allroles`,   
    deleteRoles:`${API_BASE_URL}/api/v1/roles`,     
    addRole:`${API_BASE_URL}/api/v1/roles`,      
    getUsers: `${API_BASE_URL}/api/v1/allusers`,
    getUserById:`${API_BASE_URL}/api/v1/users`,
    searchUser:`${API_BASE_URL}/api/v1/allusers`,
    addUser:`${API_BASE_URL}/api/v2/users`,
    deleteUser:`${API_BASE_URL}/api/v1/users`,
    updateUser:`${API_BASE_URL}/api/v1/users/edit-profile/`,
    getTimeSheet:`${API_BASE_URL}/api/v1/alltimesheets`,  
    addTimeSheet:`${API_BASE_URL}/api/v1/timesheets`,
    getUserAttendence:`${API_BASE_URL}/api/v1/allattendance`,
    putAttendence:`${API_BASE_URL}/api/v1/attendance`,
    getClients:`${API_BASE_URL}/api/v1/allclients`,
    searchClients:`${API_BASE_URL}/api/v1/allclients`,
    addClient:`${API_BASE_URL}/api/v1/clients`,
    editClient:`${API_BASE_URL}/api/v1/clients`,
    deleteClient:`${API_BASE_URL}/api/v1/clients`,
    getProject:`${API_BASE_URL}/api/v1/allprojects`,
    getProjectByUserId:`${API_BASE_URL}/api/v1/allprojects`,
    addProject:`${API_BASE_URL}/api/v1/projects`,
    getAllProjectStatus:`${API_BASE_URL}/api/v1/project_status`,
    getTasks:`${API_BASE_URL}/api/v1/alltasks?`,
    addTask:`${API_BASE_URL}/api/v1/tasks`,
    getTaskTypes:`${API_BASE_URL}/api/v1/tasktypes`,
    getAttendenceReport:`${API_BASE_URL}/api/v1/allattendancerecordcsv`,
    getCheckInOutReport:`${API_BASE_URL}/api/v1/attendance_report?`,
    getTimeSheetReport:`${API_BASE_URL}/api/v1/timesheets_report?`,
    getTimeSheetWhoAreNotFilledReport:`${API_BASE_URL}/api/v1/usersnotfilledtimesheet?`,
    getAllProjects:`${API_BASE_URL}/api/v1/allprojects`,
    addProjectStatus:`${API_BASE_URL}/api/v1/project_statuses`,
    deleteProjectStatus:`${API_BASE_URL}/api/v1/project_statuses`,
    addTaskType:`${API_BASE_URL}/api/v1/tasktypes`,
    deleteTaskType:`${API_BASE_URL}/api/v1/tasktypes`,
    getClientReport:`${API_BASE_URL}/api/v1/allclientscsv`,
    getUserReport:`${API_BASE_URL}/api/v1/alluserscsv`,
    getProjectReport:`${API_BASE_URL}/api/v1/allprojectscsv`,
    getAllDepartments:`${API_BASE_URL}/api/v1/alldepartments`,
    addDepartmet:`${API_BASE_URL}/api/v1/departments`,
    deleteDepartment:`${API_BASE_URL}/api/v1/departments`,
    getManagers:`${API_BASE_URL}/api/v1/managers?role=manager`,
    getAttendenceRecord:`${API_BASE_URL}/api/v1/allattendancerecord`,
  };

