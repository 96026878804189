import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Button,Toolbar,Container,List,Typography,Divider ,Box} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { Link, Outlet } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Viewwiserlogo from "../../assets/images/Viewwiser.png";
import ViewwiserIcon from "../../assets/images/Viewwiser-icon.png";
import ListItem from "../ListItem/ListItem";
import { AccountMenu } from "../Profile/Profile";
import "./DashBoard.scss";

const drawerWidth = 210;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const userName = sessionStorage.getItem("name");
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  let role;
  if (roleId === 1) {
    role = "Admin";
  } else if (roleId === 2) {
    role = "Manager";
  } else {
    role = "User";
  }

  return (
    <>
      <Box className="main-header-bar">
        <AppBar position="absolute" open={open} className="header">
          <Toolbar
            sx={{
              pr: "24px",
            }}
            className="header-toolbar"
          >
            <Button
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <Link to="/dashboard/home">
                <img className="company_icon" src={ViewwiserIcon} alt="logo" />
              </Link>
              <ChevronRightIcon style={{ marginLeft: "11px" }} />
            </Button>
            <Typography
              className="dashboard-text"
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>

            <div color="inherit" className="right-container">
              <div>
                <p className="user-name">{userName}</p>
                <p className="user-role">{role}</p>
              </div>
              <AccountMenu />
            </div>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link to="/dashboard/home">
              <img
                src={Viewwiserlogo}
                alt="logo"
                style={{ width: "120px", height: "60px" }}
              />
            </Link>
            <Button onClick={toggleDrawer}>
              <ChevronLeftIcon className="toggle-button" />
            </Button>
          </Toolbar>
          <Divider />
          <List component="nav" className="sidebar">
            <ListItem />
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" className="dashboard-card">
            <Outlet />
          </Container>
        </Box>
      </Box>
    </>
  );
}
