import React, { useState, useEffect } from "react";
import axios from "axios";
import { ApiEndPoints } from "../ApiEndPoints";
import { Grid, MenuItem, TextField, Menu } from "@mui/material";
import "./Report.scss";
import SearchIcon from "@mui/icons-material/Search";

const Report = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const [userData, setUserData] = useState();
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [error, setError] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [timesheetButton, setTimesheetButton] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const userId = sessionStorage.getItem("userId");
  const [searchError, setSearchError] = useState("");
  const [isSearchEnable, setIsSearchEnable] = useState(false);
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const isUser = roleId === 3 ? true : false;
  const [user_Id, setUser_Id] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (isUser) {
      setUser_Id(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmployees = () => {
    const url = `${ApiEndPoints.searchUser}?search_term=${searchTerm}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const users = res.data.data.users;
        setUserData(users);
        setAnchorEl(document.getElementById("search-input"));
      })
      .catch((err) => console.log(err));
  };
  function endOfDayEpochTime(dateString) {
    const date = new Date(dateString + "T23:59:59Z");
    const epochTime = date.getTime();
    return Math.floor(epochTime);
  }
  const getCheckInOut = () => {
    const start_date = new Date(startDateValue).getTime();
    const end_date = endOfDayEpochTime(endDateValue);
    const url = `${ApiEndPoints.getCheckInOutReport}?page=0&user_id=${user_Id}&start_date=${start_date}&end_date=${end_date}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "checkIn/Out_report.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error(error));
  };

  const getAttendence = () => {
    const start_date = new Date(startDateValue).getTime();
    const end_date = endOfDayEpochTime(endDateValue);
    const url = `${ApiEndPoints.getAttendenceReport}?page=1&page_size=500&user_id=${user_Id}&start_date=${start_date}&end_date=${end_date}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "attendance_report.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error(error));
  };

  const getTimeSheet = () => {
    const start_date = new Date(startDateValue).getTime();
    const end_date = endOfDayEpochTime(endDateValue);
    const url = `${ApiEndPoints.getTimeSheetReport}user_id=${user_Id}&start_date=${start_date}&end_date=${end_date}&timesheet_status_id=2`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TimeSheet_report.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error(error));
  };

  const getNotFilledTimeSheet = () => {
    const start_date = new Date(startDateValue).getTime();
    const end_date = endOfDayEpochTime(endDateValue);
    const url = `${ApiEndPoints.getTimeSheetWhoAreNotFilledReport}start_date=${start_date}&end_date=${end_date}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "NonFilledTimesheet_report.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error(error));
  };

  const updateButtonState = (start, end, userId) => {
    setIsButtonEnabled(start && end && userId);
  };

  const updateTimesheetButton = (start, end) => {
    setTimesheetButton(start && end);
  };

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    if (selectedStartDate > endDateValue && endDateValue !== "") {
      setError("Start date must be before end date");
    } else {
      setStartDateValue(selectedStartDate);
      updateTimesheetButton(selectedStartDate, endDateValue);
      setError("");
      updateButtonState(selectedStartDate, endDateValue, user_Id);
    }
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    if (selectedEndDate < startDateValue && startDateValue !== "") {
      setError("End date must be after start date");
    } else {
      setEndDateValue(selectedEndDate);
      updateTimesheetButton(startDateValue, selectedEndDate);
      setError("");
      updateButtonState(startDateValue, selectedEndDate, user_Id);
    }
  };

  useEffect(() => {
    let timer;
    if (searchTerm && isSearchEnable) {
      timer = setTimeout(() => {
        setUserData(null);
        getEmployees();
      }, 2000);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm.length < 2) {
      setSearchError("Search term must be at least 3 characters to search ");
    } else {
      setIsSearchEnable(true);
      setSearchError("");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setUser_Id(option.user_id);
    updateButtonState(startDateValue, endDateValue, option.user_id);
    setSelectedOption(`${option.first_name} ${option.last_name}`);
    setAnchorEl(null);
  };

  return (
    <div className="report-section">
      {!isUser && (
        <>
          <p>Employee Reports</p>
          <div className="search-section">
            <div>
              <div className="search-container">
                <input
                  id="search-input"
                  label="Search"
                  placeholder="Search Employee"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="search-input"
                />
                <SearchIcon style={{ cursor: "pointer" }} />
              </div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {userData &&
                  userData.map((option) => (
                    <MenuItem
                      key={option.user_id}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option.first_name} {option.last_name}
                    </MenuItem>
                  ))}
              </Menu>
            </div>

            <div className="emplyee-name-secton">
              {selectedOption && (
                <h3>
                  Employee Name: {selectedOption ? selectedOption : "None"}
                </h3>
              )}
            </div>
            {searchError && <div className="search-error">{searchError}</div>}
          </div>
        </>
      )}

      <div className="second-section">
        <Grid container spacing={2} className="date-section">
          <Grid item xs={4}>
            <p>Get Reports</p>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              value={startDateValue}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {error && (
              <h2 style={{ fontSize: "12px", color: "red", marginTop: "20px" }}>
                {error}
              </h2>
            )}
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              value={endDateValue}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <div className="button-div">
          <button
            onClick={getCheckInOut}
            disabled={!isButtonEnabled}
            className={`button-${isButtonEnabled ? "enabled" : "disabled"}`}
          >
            Check In / Out
          </button>
          <button
            onClick={getAttendence}
            disabled={!isButtonEnabled}
            className={`button-${isButtonEnabled ? "enabled" : "disabled"}`}
          >
            Attendence
          </button>
          <button
            onClick={getTimeSheet}
            disabled={!isButtonEnabled}
            className={`button-${isButtonEnabled ? "enabled" : "disabled"}`}
          >
            TimeSheet
          </button>
          {!isUser && (
            <button
              onClick={getNotFilledTimeSheet}
              disabled={!timesheetButton}
              className={`button-${isButtonEnabled ? "enabled" : "disabled"}`}
            >
              Unrecorded TimeSheet
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;
