import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import DataTable from "../DataTable/DataTable";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../Utils/Toast";
import { ApiEndPoints } from "../ApiEndPoints";
import "./Project.scss";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import Popup from "../PopUp/PopUp.jsx";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { Button_TEXTS, DefaultRowsPerPage } from "../../constants.js";

const Project = () => {
  const navigate = useNavigate();
  const [isSearchEnable, setIsSearchEnable] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const accessToken = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId");
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const isActionEnabled = roleId === 1 ? true : false;
  const [sortOrder, setSortOrder] = useState("asc");
  const user_ID = roleId === 1 ? 0 : userId;
  const [headers, setHeaders] = useState([
    { key: "project_name", value: "Project Name" },
    { key: "client_name", value: "Client Name" },
    { key: "project_status_name", value: "Status" },
  ]);
  const options = [
    { key: "project_description", value: "Description" },
    { key: "start_date", value: "Start Date" },
    { key: "due_date", value: "Due Date" },
    { key: "project_manager_name", value: "Manager Name" },
  ];
  const [projectData, setProjectData] = useState(null);
  const [page, setPage] = useState({
    totalRows: 0,
    currentPage: 1,
    rowsPerPage: DefaultRowsPerPage,
  });

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const getProject = () => {
    const url = `${ApiEndPoints.getProject}?&employee_id=${user_ID}&page=${page.currentPage}&page_size=${page.rowsPerPage}&search_term=${searchTerm}&sort_by=${sortOrder}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setProjectData(res.data.data.projects);
        setPage({ ...page, totalRows: res.data.data.total_projects });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.rowsPerPage, page.currentPage, sortOrder]);

  const deleteProject = (i) => {
    setShowToast(false);
    const id = projectData[i].project_id;
    const url = `${ApiEndPoints.addProject}/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          projectData.splice(i, 1);
          setProjectData([...projectData]);
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
        }
      })
      .catch((error) => {});
  };

  const editProject = (i) => {
    const editData = projectData[i];
    navigate("/dashboard/editProject", { state: { singleProp: editData } });
  };

  const handleChangePage = (num) => {
    setPage({ ...page, currentPage: num });
  };

  const handleChangeRowsPerPage = (row) => {
    setPage({ ...page, rowsPerPage: row, currentPage: 1 });
  };

  useEffect(() => {
    let timer;
    if (searchTerm && isSearchEnable) {
      timer = setTimeout(() => {
        setProjectData(null);
        setPage({ ...page, currentPage: 1 });
        getProject();
      }, 500);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleCheckboxChange = (obj) => {
    const isChecked = headers.some((item) => item.key === obj.key);
    if (isChecked) {
      setHeaders((prevState) =>
        prevState.filter((item) => item.key !== obj.key)
      );
    } else {
      setHeaders((prevState) => [...prevState, obj]);
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm.length < 2) {
      setSearchError("Search term must be at least 3 characters to search ");
    } else {
      setIsSearchEnable(true);
      setSearchError("");
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  const handleReportDownload = () => {
    fetch(ApiEndPoints.getProjectReport, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Project_Report.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="project-section">
      <div className="project-header-wrap">
        <p>Project List</p>
        <div className="wrapper-right-container">
          <div className="search-container">
            <input
              type="text"
              id="searchUser"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search Project"
              className="search-input"
            />
            <SearchIcon style={{ cursor: "pointer" }} />
          </div>
          <div className="dropdown-section">
            <div onClick={toggleDropdown} className="dropDown-button">
              <SettingsIcon />
            </div>
            <p className="helper-text">Add Colums </p>

            {isOpen && (
              <div className="dropdown-content">
                <h5>Add colums</h5>
                <button onClick={handleCloseDropdown}>X</button>

                {options.map((option) => (
                  <label key={option.key} style={{ display: "block" }}>
                    <input
                      type="checkbox"
                      checked={headers.some((item) => item.key === option.key)}
                      onChange={() => handleCheckboxChange(option)}
                    />
                    {option.value}
                  </label>
                ))}
              </div>
            )}
          </div>
          <div className="download-section">
            <div className="download-button" onClick={handleReportDownload}>
              <FileOpenIcon />
            </div>
            <p className="download-button-helper-text">Export to CSV</p>
          </div>
          {isActionEnabled && (
            <Link to="/dashboard/addProject">
              <Button className="add-project-button">
                {Button_TEXTS.addProject}
              </Button>
            </Link>
          )}
        </div>
      </div>
      {searchError && <div className="search-error">{searchError}</div>}
      <Popup
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => {
          deleteProject(deleteIndex);
          setShowConfirmation(false);
        }}
      />
      <div>
        <DataTable
          columns={headers}
          rows={projectData}
          edit={!isActionEnabled}
          sortOrder={sortOrder}
          toggleSortOrder={toggleSortOrder}
          action={isActionEnabled}
          editItem={editProject}
          deleteItem={(i) => {
            setShowConfirmation(true);
            setDeleteIndex(i);
          }}
          pageConfig={page}
          pageChanged={handleChangePage}
          rowsChanged={handleChangeRowsPerPage}
        />
      </div>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default Project;
