import React from "react";
import { IconButton, Typography, Select, MenuItem } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { PageSizes } from "../../constants";
import "./Pagination.scss";

const Pagination = ({
  pageDetails,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const { totalRows, rowsPerPage, currentPage } = pageDetails;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const handlePreviousPage = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <div className="pagination-section">
      <div className="pagination-div">
        <Typography variant="body1">Rows per page:</Typography>
        <Select
          id="rows-per-page-select"
          name="rowsPerPage"
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          size="small"
        >
          {PageSizes &&
            PageSizes.map((page) => (
              <MenuItem key={page} value={page}>
                {page}
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className="pagination-div">
        <Typography variant="body1">{`Page ${currentPage} of ${totalPages}`}</Typography>
        <span>
          <IconButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            <NavigateBefore />
          </IconButton>
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <NavigateNext />
          </IconButton>
        </span>
      </div>
    </div>
  );
};

export default Pagination;
