/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
  Button,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, Link } from "react-router-dom";
import Toast from "../Utils/Toast";
import { ApiEndPoints } from "../ApiEndPoints";
import axios from "axios";
import { Button_TEXTS, CountryList } from "../../constants";
import "./AddUser.scss";

const AddUser = () => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    country_nationality: "",
    department: "",
    email: "",
    countryResident: "",
    contact_number: "",
    role: [],
    manager_id: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    email: "",
    contact_number: "",
  });
  const [manager, setManager] = useState();
  const accessToken = sessionStorage.getItem("accessToken");
  const [roles, setRoles] = useState();
  const [department, setDepartment] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    var updatedValue = value;
    if (name === "dob") {
      var dateObject = new Date(value);
      updatedValue = dateObject.getTime();
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };


  const handleSubmit = async (e) => {
    setShowToast(false);
    e.preventDefault();
    if (
      validateFirstName() &&
      validateLastName() &&
      validateEmail() &&
      validateDateOfBirth() &&
      validateMobileNumber()
    ) {
      const url = ApiEndPoints.addUser;
      fetch(url, {
        method: "POST",

        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("error");
            setMessage(error);
          } else {
            const error = (data && data.message) || response.status;
            setShowToast(true);
            setType("success");
            setMessage(error);
            setTimeout(() => {
              navigate("/dashboard/users");
            }, 500);
          }
        })
        .catch((error) => {});
    }
  };

  const getRoles = () => {
    axios
      .get(ApiEndPoints.getRoles, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const roles = res.data.data;
        const role = roles.map((e) => {
          return [e.role_name];
        });
        setRoles(role);
      })
      .catch((error) => {});
  };

  const getDepartment = () => {
    axios
      .get(ApiEndPoints.getAllDepartments, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setDepartment(res.data.data);
      })
      .catch((error) => {});
  };

  const getManagers = () => {
    axios
      .get(ApiEndPoints.getManagers, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setManager(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getRoles();
    getDepartment();
    getManagers();
  }, []);

  const validateFirstName = () => {
    const firstNameRegex = /^[a-zA-Z\s]*$/;
    setFormData((prevData) => ({
      ...prevData,
      first_name: formData.first_name.trim(),
    }));

    if (!formData.first_name || !firstNameRegex.test(formData.first_name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "First name must contain only letters",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      first_name: "",
    }));
    return true;
  };

  const validateLastName = () => {
    const firstNameRegex = /^[a-zA-Z\s]*$/;
    setFormData((prevData) => ({
      ...prevData,
      last_name: formData.last_name.trim(),
    }));

    if (!formData.last_name || !firstNameRegex.test(formData.last_name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "First name must contain only letters",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      last_name: "",
    }));
    return true;
  };

  //Validation for email
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setFormData((prevData) => ({
      ...prevData,
      email: formData.email.trim(),
    }));
    if (!formData.email || !emailRegex.test(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));
    return true;
  };

  const validateMobileNumber = () => {
    const mobileRegex = /^\+?[0-9]{10,13}$/;
    if (
      !formData.contact_number ||
      !mobileRegex.test(formData.contact_number)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact_number:
          "Mobile number must be 10 to 13 digits, optionally starting with a '+' for the country code",
      }));
      return false;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      contact_number: "",
    }));
    return true;
  };

  const validateDateOfBirth = () => {
    const dob = new Date(formData.dob);
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    if (!formData.dob || dob >= eighteenYearsAgo) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "User must be at least 18 years old",
      }));
      return false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      dob: "",
    }));
    return true;
  };

  return (
    <div className="add-employee">
      <Link to="/dashboard/users" className="navigation-section">
        <div className="employee-list-backarrow">
          <ChevronLeftIcon /> Back
        </div>
      </Link>
      <form onSubmit={handleSubmit} className="form-wrapper">
        <Grid
          className="adduser-section"
          container
          rowSpacing={2}
          columnSpacing={{ xs: 6, sm: 6, md: 6, lg: 6 }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              required
              fullWidth
              helperText={
                <span className="helper-text">{errors.first_name}</span>
              }
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              id="last_name"
              fullWidth
              required
              name="last_name"
              helperText={
                <span className="helper-text">{errors.last_name}</span>
              }
              value={formData.last_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              fullWidth
              autoComplete="email"
              required
              helperText={<span className="helper-text">{errors.email}</span>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Birth"
              name="dob"
              type="date"
              id="dob"
              required
              helperText={<span className="helper-text">{errors.dob}</span>}
              onChange={handleChange}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              label="Mobile Number"
              fullWidth
              required
              id="mobile-number"
              name="contact_number"
              value={formData.contact_number}
              onChange={handleChange}
              helperText={
                <span className="helper-text">{errors.contact_number}</span>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="department"
              select
              required
              label="Department"
              value={formData.department}
              name="department"
              onChange={handleChange}
              fullWidth
            >
              <MenuItem disabled>Select Department</MenuItem>

              {department?.map((department) => (
                <MenuItem key={department} value={department.department_id}>
                  {department.department_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="country-select"
              select
              required
              label="Country"
              value={formData.countryResident}
              name="countryResident"
              onChange={handleChange}
              fullWidth
            >
              {CountryList.map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Manager"
              required
              value={formData.manager_id}
              name="manager_id"
              onChange={handleChange}
              fullWidth
            >
              <MenuItem disabled>Select Manager</MenuItem>

              {manager?.map((manager) => (
                <MenuItem key={manager.user_id} value={manager.user_id}>
                  {manager.username}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" className="role-box">
              <InputLabel htmlFor="role">Role</InputLabel>
              <Select
                id="role"
                labelId="role"
                name="role"
                label="Role"
                required
                defaultValue=""
                onChange={handleChange}
              >
                <MenuItem disabled>Select Role</MenuItem>

                {roles?.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="gender-section">
            <FormControl
              component="fieldset"
              margin="normal"
              className="gender-fieldset"
            >
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                row
                required
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="gender-block"
              >
                <FormControlLabel
                  value="male"
                  id="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  id="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Others"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" className="add-btn">
          {Button_TEXTS.addUser}
        </Button>
      </form>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default AddUser;
