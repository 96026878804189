import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Toast_Message_Display_Time } from "../../constants";

const Toast = ({ type, message }) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  
  
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={Toast_Message_Display_Time} 
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ top: 80 }}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Toast;
