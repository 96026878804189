import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import "./DataTable.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Pagination from "../Pagination/Pagination";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DataTable = ({
  columns,
  rows,
  action,
  edit,
  sortOrder,
  toggleSortOrder,
  remove,
  deleteItem,
  editItem,
  pageConfig,
  pageChanged,
  rowsChanged,
}) => {
  const sortIcon =
    sortOrder === "asc" ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  const keysWithIcon = ["start_date_time", "project_name", "client_name"];

  return (
    <Paper className="table-paper">
      <TableContainer component={Paper} className="table-container">
        <Table className="table-body">
          <TableHead className="table-heading">
            <TableRow key={0}>
              {columns.map((header, index) => (
                <TableCell className="table-cell" key={index}>
                  {header.value}

                  {keysWithIcon.includes(header.key) && (
                    <button className="sort-icon" onClick={toggleSortOrder}>
                      {sortIcon}
                    </button>
                  )}
                </TableCell>
              ))}
              {action && (
                <TableCell
                  className="table-cell"
                  style={{
                    paddingLeft: "50px",
                  }}
                >
                  Action
                </TableCell>
              )}
              {remove && <TableCell className="table-cell">Delete</TableCell>}
              {edit && <TableCell className="table-cell">Edit</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody className="table-body-section">
            {rows && rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((item) => (
                    <TableCell className="table-cell" key={item.key}>
                      {row[item.key]}
                    </TableCell>
                  ))}
                  {action && (
                    <TableCell className="table-cell action-button">
                      <div className="action-button">
                        <Button
                          id={`delete-${index}`}
                          onClick={() => deleteItem(index)}
                        >
                          <DeleteIcon />
                        </Button>

                        <Button
                          id={`edit-${index}`}
                          onClick={() => editItem(index)}
                        >
                          <EditIcon />
                        </Button>
                      </div>
                    </TableCell>
                  )}
                  {remove && (
                    <TableCell className="table-cell">
                      <Button
                        id={`delete-${index}`}
                        onClick={() => deleteItem(index)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  )}
                  {edit && (
                    <TableCell className="table-cell">
                      <Button
                        id={`edit-${index}`}
                        onClick={() => editItem(index)}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>
                  <p className="no-data-message-text">No Data Available</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows && (
        <Pagination
          pageDetails={pageConfig}
          onPageChange={pageChanged}
          onRowsPerPageChange={rowsChanged}
        />
      )}
    </Paper>
  );
};

export default DataTable;
