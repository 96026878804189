import React, { useState, useEffect } from "react";
import { TextField, Grid, Button } from "@mui/material";
import DataTable from "../DataTable/DataTable.jsx";
import Toast from "../Utils/Toast";
import { ApiEndPoints } from "../ApiEndPoints";
import ConfirmBox from "../PopUp/ConfirmBox";
import { MenuItem } from "@mui/material";
import "./Attendence.scss";
import { Button_TEXTS, DefaultRowsPerPage } from "../../constants.js";

const Attendence = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userId = parseInt(sessionStorage.getItem("userId"));
  const [user_id, setUser_id] = useState(userId);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];
  const selectedDate = currentDate;
  const [isClicked, setClicked] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [error, setError] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState("weekly");
  const [attendenceData, setAttendenceData] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [headers, setHeaders] = useState([
    { key: "start_date_time", value: "In Time and Date" },
    { key: "end_date_time", value: "Out Time and Date" },
    { key: "total_hours", value: "Total Time (HH:MM)" },
  ]);
  const [data, setData] = useState({
    user_id: userId,
    start_date_time: getTime(),
    end_date_time: getTime(),
  });
  const [page, setPage] = useState({
    totalRows: 0,
    currentPage: 1,
    rowsPerPage: DefaultRowsPerPage,
  });

  function getTime() {
    var currentTimestamp = Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds(),
      new Date().getUTCMilliseconds()
    );
    return currentTimestamp;
  }

  function calculateTimeDifference(timestamp) {
    var currentTime = new Date().getTime();
    var timestampInSeconds = timestamp / 1000;
    var currentTimeInSeconds = currentTime / 1000;
    var timeDifferenceInSeconds = currentTimeInSeconds - timestampInSeconds;
    var hours = Math.floor(timeDifferenceInSeconds / 3600);
    var minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
    return { hours: hours, minutes: minutes };
  }

  const [timeDifference, setTimeDifference] = useState({
    hours: 0,
    minutes: 0,
  });

  function updateTimeDifference(timestamp) {
    const difference = calculateTimeDifference(timestamp);
    setTimeDifference(difference);
    const intervalId = setInterval(() => {
      const difference = calculateTimeDifference(timestamp);
      setTimeDifference(difference);
    }, 60000);
  }

  const checkIntime = parseInt(localStorage.getItem("checkInTime"));

  useEffect(() => {
    const result = localStorage.getItem("checkin");
    const booleanValue = JSON.parse(result);
    if (booleanValue) {
      updateTimeDifference(checkIntime);
      setClicked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [attendenceParam, setAttendenceParam] = useState("my_self");
  const [attendenceType, setAttendenceType] = useState("attendance");

  useEffect(() => {
    if (selectedOption === "weekly") {
      getWeeklyAttendence();
    } else if (selectedOption === "monthly") {
      getMonthlyAttendence();
    } else if (selectedOption === "range" && isButtonEnabled) {
      getAttendence(startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page.rowsPerPage,
    page.currentPage,
    selectedOption,
    endDate,
    sortOrder,
    attendenceParam,
    attendenceType,
  ]);

  const handleChangePage = (num) => {
    setPage({ ...page, currentPage: num });
  };

  const handleChangeRowsPerPage = (row) => {
    setPage({ ...page, rowsPerPage: row, currentPage: 1 });
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  function getPreviousWeekDate() {
    const currentDate = new Date();
    const previousWeekDate = new Date(
      currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
    );
    return previousWeekDate;
  }

  function getPreviousMonthDate() {
    const currentDate = new Date();
    const previousWeekDate = new Date(
      currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
    );
    return previousWeekDate;
  }

  function getWeeklyAttendence() {
    const endDate = new Date().getTime();
    const startDate = getPreviousWeekDate().getTime();
    getAttendence(startDate, endDate);
  }

  function getMonthlyAttendence() {
    const endDate = new Date().getTime();
    const startDate = getPreviousMonthDate().getTime();
    getAttendence(startDate, endDate);
  }

  function timestampConversion(timestamp) {
    const date = new Date(timestamp);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: timeZone,
    };
    const dateTimeString = date.toLocaleString("en-IN", options);
    return dateTimeString;
  }

  function getAttendence(start_date, end_date) {
    setShowToast(false);
    setAttendenceData(null);
    let url;
    if (attendenceType === "attendance") {
      url = `${ApiEndPoints.getAttendenceRecord}?page=${page.currentPage}&page_size=${page.rowsPerPage}&user_id=${user_id}&start_date=${start_date}&end_date=${end_date}&sort_by=${sortOrder}`;
    } else {
      url = `${ApiEndPoints.getUserAttendence}?page=${page.currentPage}&page_size=${page.rowsPerPage}&user_id=${user_id}&start_date=${start_date}&end_date=${end_date}&sort_by=${sortOrder}`;
    }
    console.log(url);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const datas = data.data.attendances;
          setPage({ ...page, totalRows: data.data.total_attendance });

          const convertedData = datas.map((attendance) => ({
            ...attendance,
            start_date_time: timestampConversion(attendance.start_date_time),
            end_date_time: timestampConversion(attendance.end_date_time),
          }));
          setAttendenceData(convertedData);
        }
      })
      .catch((error) => {});
  }

  const handleCheckIn = (e) => {
    setShowToast(false);
    setData({
      ...data,
      start_date_time: getTime(),
      end_date_time: getTime(),
    });
    const url = `${ApiEndPoints.putAttendence}`;
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          localStorage.setItem("checkInTime", getTime());
          localStorage.setItem("checkin", JSON.stringify(true));
          localStorage.setItem("attendenceId", data.data.attendance_id);
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          const checkIntime = localStorage.getItem("checkInTime");
          updateTimeDifference(checkIntime);
          setClicked(true);
          getWeeklyAttendence();
        }
      })
      .catch((error) => {});
  };

  function handleCheckOut() {
    setShowToast(false);
    const attendenceId = parseInt(localStorage.getItem("attendenceId"));
    const url = `${ApiEndPoints.putAttendence}/${attendenceId}`;
    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ end_date_time: getTime() }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          localStorage.setItem("checkin", JSON.stringify(false));
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setClicked(false);
          setType("success");
          setMessage(error);
          getWeeklyAttendence();
        }
      })
      .catch((error) => {});
  }

  const checkOut = () => {
    setShowConfirmation(true);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;

    if (selectedStartDate > endDateValue && endDateValue !== "") {
      setError("Start date must be before end date");
    } else {
      setStartDateValue(selectedStartDate);
      setError("");
      updateButtonState(selectedStartDate, endDateValue, error);
    }
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;

    if (selectedEndDate < startDateValue && startDateValue !== "") {
      setError("End date must be after start date");
    } else {
      setEndDateValue(selectedEndDate);
      setError("");
      updateButtonState(startDateValue, selectedEndDate, error);
    }
  };

  const updateButtonState = (start, end, error) => {
    setIsButtonEnabled(start !== "" && end !== "" && error === "");
  };

  function dateToEpochTime(dateString) {
    const date = new Date(dateString);
    return date.getTime();
  }

  function endOfDayEpochTime(dateString) {
    const date = new Date(dateString + "T23:59:59Z");
    const epochTime = date.getTime();
    return Math.floor(epochTime);
  }

  const handleGetData = () => {
    setStartDate(dateToEpochTime(startDateValue));
    setEndDate(endOfDayEpochTime(endDateValue));
  };

  const handleAttendenceParam = (event) => {
    if (event.target.value === "all_users") {
      const isChecked = headers.some((item) => item.key === "user_name");
      if (isChecked) {
        setHeaders((prevState) =>
          prevState.filter((item) => item.key !== "user_name")
        );
      } else {
        setHeaders((prevState) => [
          ...prevState,
          { key: "user_name", value: "User Name" },
        ]);
      }
      setUser_id(0);
    } else {
      setHeaders((prevState) =>
        prevState.filter((item) => item.key !== "user_name")
      );
    }
    setAttendenceParam(event.target.value);
  };

  const handleAttendenceType = (event) => {
    setAttendenceType(event.target.value);
  };

  return (
    <div className="attendence-container">
      <div className="first-section">
        <TextField
          label="Date"
          name="date"
          id="date"
          type="date"
          value={selectedDate}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div>
        <ConfirmBox
          isOpen={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          onConfirm={() => {
            handleCheckOut();
            setShowConfirmation(false);
          }}
        />
      </div>
      <div className="second-section">
        <div className="first-box">
          <button
            className={`checkin-button ${isClicked ? "disabled" : ""}`}
            onClick={handleCheckIn}
            disabled={isClicked}
          >
            {Button_TEXTS.checkIn}
          </button>

          <button
            className={`checkout-button ${!isClicked ? "disabled" : ""}`}
            onClick={checkOut}
            disabled={!isClicked}
          >
            {Button_TEXTS.checkOut}
          </button>
        </div>
        {isClicked && (
          <div className="status-text">
            <p>
              Check in at : <span> {timestampConversion(checkIntime)}</span>
            </p>
            <p>
              Check in time : <span>{timeDifference.hours}</span> Hours and
              <span> {timeDifference.minutes} </span>Minutes
            </p>
          </div>
        )}
      </div>

      <div className="fourth-section">
        <div className="main-div">
          <div className="first-section">
            <TextField
              select
              label="Attendence Type"
              value={attendenceType}
              onChange={handleAttendenceType}
            >
              <MenuItem value={"attendance"}>Attendance</MenuItem>
              <MenuItem value="punch_in_out">Punch In / Out</MenuItem>
            </TextField>

            {roleId === 1 && (
              <div className="radio-container-first">
                <label className="radio-button-section">
                  <input
                    id="my_self"
                    type="radio"
                    value="my_self"
                    checked={attendenceParam === "my_self"}
                    onChange={handleAttendenceParam}
                  />
                  My Self
                </label>
                <label className="radio-button-section">
                  <input
                    id="all_users"
                    type="radio"
                    value="all_users"
                    checked={attendenceParam === "all_users"}
                    onChange={handleAttendenceParam}
                  />
                  All Users
                </label>
              </div>
            )}

            {roleId === 2 && (
              <div className="radio-container-first">
                <label className="radio-button-section">
                  <input
                    id="my_self"
                    type="radio"
                    value="my_self"
                    checked={attendenceParam === "my_self"}
                    onChange={handleAttendenceParam}
                  />
                  My Self
                </label>
                <label className="radio-button-section">
                  <input
                    id="my_team"
                    type="radio"
                    value="my_team"
                    checked={attendenceParam === "my_team"}
                    onChange={handleAttendenceParam}
                  />
                  My Team
                </label>
              </div>
            )}
          </div>
          <div className="radio-container">
            <label className="radio-button-section">
              <input
                id="weekly"
                type="radio"
                value="weekly"
                checked={selectedOption === "weekly"}
                onChange={handleOptionChange}
              />
              Weekly
            </label>
            <label className="radio-button-section">
              <input
                id="monthly"
                type="radio"
                value="monthly"
                checked={selectedOption === "monthly"}
                onChange={handleOptionChange}
              />
              Monthly
            </label>
            <label className="radio-button-section">
              <input
                id="range"
                type="radio"
                value="range"
                checked={selectedOption === "range"}
                onChange={handleOptionChange}
              />
              Date Range
            </label>
            {selectedOption === "range" && (
              <div>
                <Grid container spacing={4} className="date-range-box">
                  <Grid item xs={4}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDateValue}
                      onChange={handleStartDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="End Date"
                      type="date"
                      value={endDateValue}
                      onChange={handleEndDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      className={`button-${
                        isButtonEnabled ? "enabled" : "disabled"
                      }`}
                      onClick={handleGetData}
                      disabled={!isButtonEnabled}
                    >
                      Get Attendence
                    </Button>
                  </Grid>
                </Grid>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            )}
          </div>
        </div>
        <div className="table-section">
          <DataTable
            columns={headers}
            rows={attendenceData}
            sortOrder={sortOrder}
            toggleSortOrder={toggleSortOrder}
            pageConfig={page}
            pageChanged={handleChangePage}
            rowsChanged={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default Attendence;
