import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, MenuItem } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./Addtask.scss";
import axios from "axios";
import Toast from "../Utils/Toast";
import { useNavigate, Link } from "react-router-dom";
import { ApiEndPoints } from "../ApiEndPoints";
import { Button_TEXTS, TEXT_MSGS } from "../../constants";

const AddTask = () => {
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const [taskType, setTaskType] = useState(null);
  const [projectIds, setProjectIds] = useState();
  const accessToken = sessionStorage.getItem("accessToken");
  const user_ID = roleId === 1 ? 0 : userId;

  const [formData, setFormData] = useState({
    project_id: "",
    task_type_id: "",
    task_name: "",
    user_id: parseInt(userId),
  });

  

  function getAllProject() {
    axios
      .get(
        `${ApiEndPoints.getProjectByUserId}?page=1&page_size=1000&employee_id=${user_ID}&sort_by=asce`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        const projects = res.data.data.projects;
        setProjectIds(projects);
        console.log(projects)
      })
      .catch((err) => {});
  }

  const getAllTasks = () => {
    axios
      .get(`${ApiEndPoints.getTaskTypes}?page=1&page_size=100&sort_by=asce`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const tasks = res.data.data.taskTypes;
        setTaskType(tasks);
      })
      .catch((err) => console.log(err), "eee");
  };

  useEffect(() => {
    getAllProject();
    getAllTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    console.log(user_ID);
    const { name, value } = e.target;
    let formattedValue = value;
    if (name.includes("_id")) {
      formattedValue = parseInt(value, 10);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiUrl = ApiEndPoints.addTask;
    fetch(apiUrl, {
      method: "POST",

      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("error");
          setMessage(error);
        } else {
          const error = (data && data.message) || response.status;
          setShowToast(true);
          setType("success");
          setMessage(error);
          setTimeout(() => {
            navigate("/dashboard/task");
          }, 300);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="addTask-section">
      <Link
        className="task-list-backarrow"
        to="/dashboard/task"
        onClick={() => navigate("/dashboard/task")}
      >
        <ChevronLeftIcon /> {TEXT_MSGS.back}
      </Link>

      <div>
        <form onSubmit={handleSubmit} className="form-wrapper">
          <Grid container spacing={2}   className="addTask-section"
          columnSpacing={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                name="project_id"
                label=" Project"
                value={formData.project_id}
                onChange={handleInputChange}
              >
                {projectIds &&
                  projectIds.map((project) => (
                    <MenuItem
                      key={project.project_id}
                      value={project.project_id}
                    >
                      {project.project_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                name="task_type_id"
                label=" Task Type"
                value={formData.task_type_id}
                onChange={handleInputChange}
              >
                {taskType &&
                  taskType.map((task) => (
                    <MenuItem key={task.taskid} value={task.task_type_id}>
                      {task.task_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Task Description"
                fullWidth
                required
                name="task_description"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Button variant="contained" type="submit">
            {Button_TEXTS.addTask}
          </Button>
        </form>
      </div>
      {showToast && <Toast type={type} message={message} />}
    </div>
  );
};

export default AddTask;
